<template>
    <div class="status-bar">
        <div class="row">
            <span class="title">{{ tokens.statusBar.balance }}:</span>
            <span class="content">{{
                    currencySign(activeAccount.currency) + (activeAccount.balance > 0 ? activeAccount.balance : 0).toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
                }}</span>
        </div>
        <div class="row">
            <span class="title">{{ tokens.statusBar.credit }}:</span>
            <span class="content">{{
                    currencySign(activeAccount.currency) + (activeAccount.credit > 0 ? activeAccount.credit : 0).toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
                }}</span>
        </div>

        <div class="more-info" :class="{hide: margin === 0 || isMobile()}">
            <div class="row">
                <span class="title">{{ tokens.statusBar.equity }}:</span>
                <span class="content">{{
                        currencySign(activeAccount.currency) + (activeAccount.balance + activeAccount.credit + pnl).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        })
                    }}</span>
            </div>
            <div class="row">
                <span class="title">{{ tokens.statusBar.margin }}:</span>
                <span class="content">{{
                        currencySign(activeAccount.currency) + margin.toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        })
                    }}</span>
            </div>
            <div class="row">
                <span class="title">{{ tokens.statusBar.marginLevel }}:</span>
                <span class="content">{{
                        activeAccount.balance + activeAccount.credit + pnl !== 0 && margin ? (100 * (activeAccount.balance + activeAccount.credit + pnl) / margin).toFixed(2) + '%' : '-'
                    }}</span>
            </div>
            <div class="row">
                <span class="title">{{ tokens.statusBar.freeMargin }}:</span>
                <span class="content">{{
                        margin !== 0 ? currencySign(activeAccount.currency) + (activeAccount.balance + activeAccount.credit + pnl - margin).toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        }) : '-'
                    }}</span>
            </div>
            <div class="row">
                <span class="title">{{ tokens.statusBar.pnl }}:</span>
                <span class="content" :class="pnl < 0 ? 'red' : (pnl > 0 ? 'green' : '')">{{
                        currencySign(activeAccount.currency) + pnl.toLocaleString(undefined, {
                            minimumFractionDigits: activeAccount.precision,
                            maximumFractionDigits: activeAccount.precision
                        })
                    }}</span>
            </div>
        </div>

        <div class="right" v-show="ordersPane && historyTableIsActive">
            <span class="title">{{ tokens.statusBar.profitTotal }}:</span>
            <span class="content" :class="profitTotal < 0 ? 'red' : (profitTotal > 0 ? 'green' : '')">{{
                    currencySign(activeAccount.currency) + profitTotal.toLocaleString(undefined, {
                        minimumFractionDigits: activeAccount.precision,
                        maximumFractionDigits: activeAccount.precision
                    })
                }}</span>
        </div>

        <i v-show="!ordersPane" class="material-icons expander pointer" @click.stop="openOrdersPane()">expand_less</i>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {currencySign, isMobile} from "@/common/helpers"
import {ORDERS_PANE} from '@/store/mutations.type'

export default {
    name: "StatusBar",
    data() {
        return {
            profitTotal: 0
        }
    },
    methods: {
        currencySign, isMobile,
        openOrdersPane() {
            this.$store.commit(ORDERS_PANE, true)
        },
    },
    computed: {
        ...mapGetters(['orders', 'symbols', 'activeAccount', 'tokens', 'ordersPane', 'historyTableIsActive']),
        pnl() {
            let pnl = 0

            for (let i in this.orders) {
                let order = this.orders[i]

                if (order.type > 1 || !this.symbols[order.symbol]) {
                    continue
                }
                pnl += order.point * (order.type === 0 ? this.symbols[order.symbol].quote.bid - order.price_open : order.price_open - this.symbols[order.symbol].quote.ask) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission
            }

            return pnl
        },
        margin() {
            let margin = 0
            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]
                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }
                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }
            }
            for(let n in marginBySymbol) {
                margin += Math.abs(marginBySymbol[n])
            }

            return margin
        }
    },
    watch: {
        activeAccount: {
            deep: true,
            handler(newVal) {
                newVal.profitTotal ? this.profitTotal = newVal.profitTotal : this.profitTotal = 0
            }
        }
    }
}
</script>

<style scoped>
.status-bar {
    flex: 1 1 auto;
    max-height: 30px;
    min-height: 30px;
    position: relative;
    display: flex;
}

.status-bar .row {
    flex-wrap: wrap;
}

.more-info {
    display: flex;
}

.right {
    flex-grow: 1;
    padding-right: 12px;
    align-self: center;
    text-align: right;
}

.row {
    flex-wrap: nowrap;
}

span {
    margin-right: 10px;
    white-space: nowrap;
}

span.content {
    margin-right: 20px;
    color: var(--white-to-black);
}

.expander {
    position: absolute;
    right: 1px;
    top: 0;
    font-size: 30px;
}
</style>
