<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.autoClose}"
         @mousedown.stop="hideModal">
        <div class="modal" @mousedown.stop
             :style="{
                top: position.top+'px',
                left: position.left+'px'
            }"
        >
            <div class="title">{{ tokens.autoCloseModal.takeProfitStopLoss.toUpperCase() }}</div>
            <div class="btns" @click.stop="" :class="{hide: values.orderActive}">
                <div class="btn"
                     :class="{active: mode === 'default'}"
                     @click="setMode('default')">
                    {{tokens.orderMenu.default}}
                </div>
            </div>
            <div class="row stretch">
                <div class="value-container value-container--grid" :class="{transparent: tpTransparent}"
                     @click="focusTP">
                    <div class="subtitle-input">
                        <span>{{ tokens.autoCloseModal.takeProfit }}</span>
                        <input type="text" class="value" v-model.number="values.tp" inputmode="decimal"
                               @keypress="validateInput"
                               @keyup="updateTpInput"
                               @blur="blurTP"
                               ref="tp"/>
                    </div>
                    <div class="plus" id="tp-plus"
                         @click.stop="values.tp = values.tp ? (parseFloat(values.tp) + Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                        <i class="material-icons">add</i>
                    </div>
                    <div class="minus" id="tp-minus"
                         @click.stop="values.tp = values.tp ? (Math.max(0, parseFloat(values.tp) - Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.bid.toFixed(modalSymbolActive.precision)">
                        <i class="material-icons">remove</i>
                    </div>
                </div>
            </div>

            <div class="row stretch">
                <div class="value-container value-container--grid" :class="{transparent: slTransparent}"
                     @click="focusSL">
                    <div class="subtitle-input">
                        <span>{{ tokens.autoCloseModal.stopLoss }}</span>
                        <input type="text" class="value" v-model.number="values.sl" inputmode="decimal"
                               @keypress="validateInput"
                               @keyup="updateSlInput"
                               @blur="blurSL"
                               ref="sl"/>
                    </div>
                    <div class="plus" id="sl-plus"
                         @click.stop="values.sl = values.sl ? (parseFloat(values.sl) + Math.pow(0.1, modalSymbolActive.precision)).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision)">
                        <i class="material-icons">add</i>
                    </div>
                    <div class="minus" id="sl-minus"
                         @click.stop="values.sl = values.sl ? (Math.max(0, parseFloat(values.sl) - Math.pow(0.1, modalSymbolActive.precision))).toFixed(modalSymbolActive.precision) : modalSymbolActive.quote.ask.toFixed(modalSymbolActive.precision)">
                        <i class="material-icons">remove</i>
                    </div>
                </div>
            </div>

            <button :class="{hide: !values.orderActive}" @click="updateOrder">{{
                    tokens.autoCloseModal.updateOrder
                }}
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, MODAL_AUTOCLOSE, SET_AUTOCLOSE_MODE} from '@/store/mutations.type'
import {currencySign, isMobile} from '@/common/helpers'
import {CFD_ORDER_MODIFY_REQUEST} from "@/store/actions.type"

export default {
    name: 'AutoCloseModal',
    data() {
        return {
            values: {
                left: 0,
                top: 0,
                symbolActive: null,
                orderActive: null,
                tp: 0,
                tpType: '=',
                sl: 0,
                slType: '=',
                item: 0
            },
            mode: 'default',
            tpTransparent: false,
            slTransparent: false
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        focusTP() {
            if (!this.tpTransparent) {
                this.tpTransparent = true
                this.$refs['tp'].focus()
            }
        },
        blurTP() {
            this.tpTransparent = false
        },
        focusSL() {
            if (!this.slTransparent) {
                this.slTransparent = true
                this.$refs['sl'].focus()
            }
        },
        blurSL() {
            this.slTransparent = false
        },
        validateInput(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','].indexOf(e.key) === -1) {
                e.preventDefault()
            }

            if ((e.key === '.' || e.key === ',') && (e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1)) {
                e.preventDefault()
            }
        },
        updateTpInput(e) {
            let tp = String(e.target.value)
            tp = tp.includes(',') ? tp.replace(/,/, '.') : tp
            tp = parseFloat(tp)
            !isNaN(tp) ? this.values.tp = tp : ''
        },
        updateSlInput(e) {
            let sl = String(e.target.value)
            sl = sl.includes(',') ? sl.replace(/,/, '.') : sl
            sl = parseFloat(sl)
            !isNaN(sl) ? this.values.sl = sl : ''
        },
        updateOrder() {
            const orderKeys = Object.keys(this.activeAccount.orders)
            const orderIndex = orderKeys.find(orderKey => this.activeAccount.orders[orderKey].id === this.values.orderActive)

            if (this.activeAccount.orders[orderIndex] === undefined) {
                this.$store.commit(MODAL, {
                    autoClose: false
                })

                return
            }

            let price = this.activeAccount.orders[orderIndex].price_open,
                type = this.activeAccount.orders[orderIndex].type,
                leverage = this.activeAccount.orders[orderIndex].easy_leverage || this.activeAccount.leverage,
                amount = this.activeAccount.orders[orderIndex].easy_amount,
                sl = 0,
                tp = 0

            switch (this.values.tpType) {
                case '%':
                    tp = Number(price) + (type % 2 ? -1 : 1) * price * this.values.tp / (100 * leverage)
                    break
                case '$':
                    tp = Number(price) + (type % 2 ? -1 : 1) * price * this.values.tp / (100 * leverage * amount)
                    break
                case '=':
                    tp = String(this.values.tp)
                    tp = tp.includes(',') ? tp.replace(/,/, '.') : tp
                    tp = parseFloat(tp)
                    break
            }

            switch (this.values.slType) {
                case '%':
                    sl = Number(price) + (type ? 1 : -1) * price * this.values.sl / (100 * this.leverage)
                    break
                case '$':
                    sl = Number(price) + (type ? 1 : -1) * price * this.values.sl / (this.leverage * this.amount)
                    break
                case '=':
                    sl = this.values.sl
                    break
            }

            if (this.values.tp == 0 || this.values.tp === '') {
                tp = 0
            }

            if (this.values.sl == 0 || this.values.sl === '') {
                sl = 0
            }

            this.$store.dispatch(CFD_ORDER_MODIFY_REQUEST, {
                id: this.values.orderActive,
                account_id: this.activeAccount.id,
                sl,
                tp
            })

            this.$store.commit(MODAL, {
                autoClose: false
            })
        },
        setMode(mode) {
            this.mode = mode
            this.$store.commit(SET_AUTOCLOSE_MODE, mode)
        },
        currencySign,
        isMobile
    },
    computed: {
        ...mapGetters(['modals', 'modalAutoClose', 'tokens', 'activeAccount', 'symbols', 'orders']),
        position() {
            if (!this.modals.autoClose) {
                return {
                    left: 0,
                    top: 0
                }
            }

            let screen = {
                    width: document.body.offsetWidth,
                    height: document.body.offsetHeight,
                },
                modal = {
                    width: 200,
                    height: this.values.orderActive ? 262 : 209
                }

            if (screen.width >= 600 && this.isMobile()) {
                return {
                    left: this.modalAutoClose.posX,
                    top: this.modalAutoClose.posY - 29 * 3
                }
            }

            if (screen.width < 600 && this.isMobile()) {
                return {
                    left: screen.width - modal.width,
                    top: this.modalAutoClose.top - 29 * 3
                }
            }
            return {
                left: this.modalAutoClose.left - modal.width - 10,
                top: Math.min(this.modalAutoClose.top, screen.height - modal.height)
            }
        },
        modalSymbolActive() {
            if (this.values?.symbolActive === null) {
                const symbol = this.orders?.[this.values?.item]?.symbol
                if (symbol) {
                    this.$store.commit(MODAL_AUTOCLOSE, {symbolActive: this.symbols[symbol]})
                }
            }
            return this.modalAutoClose.symbolActive || {
                precision: 0,
                quote: {
                    bid: 0,
                    ask: 0
                }
            }
        }
    },
    watch: {
        modalAutoClose: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_AUTOCLOSE, val)
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--background-dark);
    z-index: 200;
    top: 357px;
    right: 110px;
    width: 200px;
}

.title {
    padding: 15px;
    background: var(--background-dark-mid);
    font-weight: 400;
    font-size: 1.1em;
    color: var(--white-to-black);
}

.btns {
    margin-top: 1px;
    display: flex;
    align-items: stretch;
    padding: 10px 15px 0;
}

.btn {
    height: 25px;
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    background-color: var(--background-light);
    border-radius: 2px;
    cursor: pointer;
    color: var(--font);
}

.btn.active {
    color: var(--white-to-black);
}

.light-theme .btn{
    background-color: var(--aspin-background-light);
    outline: 1px solid var(--border);
}

.btn:not(:last-child) {
    margin-right: 2px;
}

.value-container {
    background-color: var(--background-light);
    border: 2px solid var(--background-dark);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: background-color ease-in-out 0.3s;
    flex: 1 1 auto;
}

.light-theme .value-container{

    background-color: var(--aspin-background-light);
    border-color: var(--border);
}

.value-container--grid {
    display: grid;
    grid-template-columns: 1fr 26px;
    grid-template-rows: 1fr 1fr;
}

.subtitle-input {
    grid-column: 1/2;
    grid-row: 1/3;
    padding: 5px;
}

input.value {
    background-color: transparent;
    border: none;
    width: 85%;
    cursor: pointer;
    font-size: 1.5em;
    margin-top: 3px;
}

.plus {
    grid-column: 2/3;
    grid-row: 1/2;
    border-left: 2px solid var(--background-dark);
    border-bottom: 2px solid var(--background-dark);
    display: grid;
}

.minus {
    grid-column: 2/3;
    grid-row: 2/3;
    border-left: 2px solid var(--background-dark);
    display: grid;
}

.plus i, .minus i {
    place-self: center center;
    font-size: 12px;
}

/* modifiers */

.value-container:hover span {
    color: var(--white-to-black);
}

.value-container.transparent {
    border: 2px solid var(--background-light);
    background-color: transparent;
}

.value-container.transparent .plus {
    border-left: 2px solid var(--background-light);
    border-bottom: 2px solid var(--background-light);
}

.value-container.transparent .minus {
    border-left: 2px solid var(--background-light);
}


.sign {
    position: absolute;
    left: 5px;
    bottom: 6px;
    font-size: 1.5em;
    color: var(--white);
    font-weight: 400;
}

.type-select {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
    font-weight: 400;
    color: var(--white);
    flex: 0 0 30px;
    background: var(--background);
    border-left: 2px solid var(--background-dark);
    padding: 10px;
    cursor: pointer;
}

.type-select-options {
    position: absolute;
    z-index: 2;
    top: 53px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 2px;
    width: 100%;
    background: var(--background-dark);
}

.type-select-options .item {
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 6px 10px;
    color: var(--white);
    font-weight: 400;
    cursor: pointer;
}

.type-select-options .item:last-child {
    border-bottom: none;
}

.type-select-options .item.active, .type-select-options .item:hover {
    background: var(--border);
}

.type-select-options .item span {
    margin-right: 10px;
}

.stretch {
    position: relative;
    padding: 15px;
}

button {
    margin: 10px 15px;
}

/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

</style>
