<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.autoTrader}"
         @click.stop="hideModal">
        <div class="modal" @click.stop>
            <div class="close" @click="hideModal">
                <i class="material-icons">close</i>
            </div>
            <div class="row form-control" :class="{active: enable}" @click="enable = !enable">
                Auto Trader
                <i class="material-icons btn" :class="enable ? 'green' : 'red'" title="Enable">toggle_{{ enable ? 'on' : 'off' }}</i>
            </div>
            <div class="modal-inner">
                <div class="row form-control" :class="{active: riskLevel.low}" @click="setRiskLevel('low')">
                    {{tokens.autoTrader.riskLevelLow}}
                    <div class="radio"></div>
                </div>
                <div class="row form-control" :class="{active: riskLevel.medium}" @click="setRiskLevel('medium')">
                    {{tokens.autoTrader.riskLevelMedium}}
                    <div class="radio"></div>
                </div>
                <div class="row form-control" :class="{active: riskLevel.high}" @click="setRiskLevel('high')">
                    {{tokens.autoTrader.riskLevelHigh}}
                    <div class="radio"></div>
                </div>
            </div>
            <div class="row form-control">
                {{tokens.autoTrader.perDay}}
                <span>{{ tradersPerDayStr[activeRiskLevel] }}</span>
            </div>
            <p class="desc">{{tokens.autoTrader.desc}}</p>
            <div class="row form-control" :class="{active: accept}" @click="accept = !accept">
                <div class="accept">{{tokens.autoTrader.accept}} <div class="radio"></div></div>
            </div>
            <div class="row actions">
                <button class="button save" @click="save()">Save</button>
                <button class="button cancel" @click="hideModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {SET_AUTOTRADER_CONFIG} from "@/store/actions.type"
import {MODAL, NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"

export default {
    name: "AutoTraderModal",
    data() {
        return {
            riskLevel: {
                low: false,
                medium: false,
                high: false,
            },
            activeRiskLevel: 'low',
            tradersPerDayStr: {
                low: '1-3',
                medium: '3-4',
                high: '5+'
            },
            tradersPerDayNum: 3,
            accept: false,
            enable: 0
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                autoTrader: false
            })
            this.setAutoTraderConfig()
            this.accept = false
        },
        deactivateRiskLevel() {
            for (const item in this.riskLevel) {
                this.riskLevel[item] = false
            }
        },
        setRiskLevel(risk) {
            this.deactivateRiskLevel()
            this.riskLevel[risk] = true
            this.activeRiskLevel = risk
        },
        save() {
            if (!this.accept) {
                this.$store.commit(NOTIFICATION_ERROR, 'Please press the `Accept` button')
                return
            }

            this.sendAutotraderConfig()
            this.hideModal()
            this.$store.commit(NOTIFICATION, 'AutoTrader activated')
        },
        sendAutotraderConfig() {
            let activeRiskLevel

            for (const item in this.riskLevel) {
                if (this.riskLevel[item]) {
                    activeRiskLevel = item
                    switch (item) {
                        case 'low':
                            this.tradersPerDayNum = 3
                            break
                        case 'medium':
                            this.tradersPerDayNum = 4
                            break
                        case 'high':
                            this.tradersPerDayNum = 30
                            break
                        default:
                            this.tradersPerDayNum = 3
                            break
                    }
                }
            }

            this.$store.dispatch(SET_AUTOTRADER_CONFIG, {
                risk_level: activeRiskLevel,
                trades_day: this.tradersPerDayNum,
                enable: this.enable
            })
        },
        setAutoTraderConfig() {
            this.deactivateRiskLevel()
            this.$set(this, 'tradersPerDayNum', this.autoTraderConfig.trades_day > 1 ? this.autoTraderConfig.trades_day : 3)
            this.$set(this.riskLevel, this.autoTraderConfig.risk_level, true)
            this.$set(this, 'activeRiskLevel', this.autoTraderConfig.risk_level)
            this.$set(this, 'enable', this.autoTraderConfig.enable)
        }
    },
    computed: {
        ...mapGetters(['modals', 'autoTraderConfig','tokens'])
    },
    watch: {
        autoTraderConfig() {
            this.setAutoTraderConfig()
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
    position: relative;
    font-size: 1.2em;
}

.close {
    background-color: var(--background);
    border-radius: 50%;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease-in-out 0.3s;
}

.close i {
    font-size: 2.5em;
    transition: color ease-in-out 0.3s;
}

.close:hover {
    background-color: var(--background-light);
}

.close:hover i {
    color: var(--white)
}

.row {
    justify-content: space-between;
}

.row.actions {
    margin-top: 20px;
}

.button.save {
    background-color: var(--green);
}

.button.cancel {
    background-color: var(--red);
}

.form-control {
    padding: 8px 10px;
}

.desc {
    margin: 20px 0;
}

.modal-inner {
    margin: 20px 0;
}

.modal-inner .row {
    margin-bottom: 15px;
}

.modal-inner .row:last-child {
    margin-bottom: 0;
}

.radio {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #2B834E;
    position: relative;
}

.form-control.active .radio:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: #2bab40;
    border-radius: 100%;
}

.accept {
    display: flex;
    gap: 5px;
    align-items: center;
}

.accept .radio {
    border-radius: 5px;
}

.accept .radio:after {
    border-radius: 3px !important;
}
</style>
