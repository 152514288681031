<template>
    <div class="amounts" :class="{hide: tabActive !== alias}">
        <div class="col">
            <div class="card">
                <div class="container preload">
                    <div
                        class="creditcard"
                        :class="{flipped: flipped}"
                        @click="flipped = !flipped"
                    >
                        <div class="front">
                            <div :ref="`ccsingle-${alias}`" class="ccsingle"></div>
                            <svg
                                version="1.1"
                                class="cardfront"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 750 471"
                                style="enable-background: new 0 0 750 471"
                                xml:space="preserve"
                            >
                        <g id="Front">
                          <g id="CardBackground">
                            <g id="Page-1_1_">
                              <g id="amex_1_">
                                <path
                                    :class="cardData.cardStyle"
                                    id="Rectangle-1_1_"
                                    class="lightcolor"
                                    d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                                C0,17.9,17.9,0,40,0z"
                                />
                              </g>
                            </g>
                              <path
                                  class="darkcolor"
                                  :class="cardData.cardStyle + 'dark'"
                                  d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z"
                              />
                          </g>
                            <text
                                transform="matrix(1 0 0 1 60.106 295.0121)"
                                id="svgnumber"
                                class="st2 st3 st4"
                            >
                            {{ cardData.cardNumber ? cardData.cardNumber : "0123 4567 8910 1112" }}
                          </text>
                            <text
                                transform="matrix(1 0 0 1 54.1064 428.1723)"
                                class="st2 st5 st6 svgname"
                            >
                            {{ cardData.name ? cardData.name : 'John Doe' }}
                          </text>
                            <text
                                transform="matrix(1 0 0 1 54.1074 389.8793)"
                                class="st7 st5 st8"
                            >
                            cardholder name
                          </text>
                            <text
                                transform="matrix(1 0 0 1 479.7754 388.8793)"
                                class="st7 st5 st8"
                            >
                            expiration
                          </text>
                            <text
                                transform="matrix(1 0 0 1 65.1054 241.5)"
                                class="st7 st5 st8"
                            >
                            card number
                          </text>
                            <g>
                            <text
                                transform="matrix(1 0 0 1 574.4219 433.8095)"
                                id="svgexpire"
                                class="st2 st5 st9"
                            >
                              {{ cardData.expiration ? cardData.expiration : '01/23' }}
                            </text>
                                <text
                                    transform="matrix(1 0 0 1 479.3848 417.0097)"
                                    class="st2 st10 st11"
                                >
                              VALID
                            </text>
                                <text
                                    transform="matrix(1 0 0 1 479.3848 435.6762)"
                                    class="st2 st10 st11"
                                >
                              THRU
                            </text>
                                <polygon
                                    class="st2"
                                    points="554.5,421 540.4,414.2 540.4,427.9 		"
                                />
                          </g>
                            <g id="cchip">
                            <g>
                              <path
                                  class="st2"
                                  d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
                            c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z"
                              />
                            </g>
                                <g>
                              <g>
                                <rect
                                    x="82"
                                    y="70"
                                    class="st12"
                                    width="1.5"
                                    height="60"
                                />
                              </g>
                                    <g>
                                <rect
                                    x="167.4"
                                    y="70"
                                    class="st12"
                                    width="1.5"
                                    height="60"
                                />
                              </g>
                                    <g>
                                <path
                                    class="st12"
                                    d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
                                c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
                                C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
                                c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
                                c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z"
                                />
                              </g>
                                    <g>
                                <rect
                                    x="82.8"
                                    y="82.1"
                                    class="st12"
                                    width="25.8"
                                    height="1.5"
                                />
                              </g>
                                    <g>
                                <rect
                                    x="82.8"
                                    y="117.9"
                                    class="st12"
                                    width="26.1"
                                    height="1.5"
                                />
                              </g>
                                    <g>
                                <rect
                                    x="142.4"
                                    y="82.1"
                                    class="st12"
                                    width="25.8"
                                    height="1.5"
                                />
                              </g>
                                    <g>
                                <rect
                                    x="142"
                                    y="117.9"
                                    class="st12"
                                    width="26.2"
                                    height="1.5"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                                <g id="Back"></g>
                      </svg>
                        </div>
                        <div class="back">
                            <svg
                                version="1.1"
                                class="cardback"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                x="0px"
                                y="0px"
                                viewBox="0 0 750 471"
                                style="enable-background: new 0 0 750 471"
                                xml:space="preserve"
                            >
                        <g id="Front">
                          <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11"/>
                        </g>
                                <g id="Back">
                          <g id="Page-1_2_">
                            <g id="amex_2_">
                              <path
                                  id="Rectangle-1_2_"
                                  class="darkcolor"
                                  :class="cardData.cardStyle + 'dark'"
                                  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
                            C0,17.9,17.9,0,40,0z"
                              />
                            </g>
                          </g>
                                    <rect y="61.6" class="st2" width="750" height="78"/>
                                    <g>
                            <path
                                class="st3"
                                d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
                        C707.1,246.4,704.4,249.1,701.1,249.1z"
                            />
                                        <rect
                                            x="42.9"
                                            y="198.6"
                                            class="st4"
                                            width="664.1"
                                            height="10.5"
                                        />
                                        <rect
                                            x="42.9"
                                            y="224.5"
                                            class="st4"
                                            width="664.1"
                                            height="10.5"
                                        />
                                        <path
                                            class="st5"
                                            d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z"
                                        />
                          </g>
                                    <text
                                        transform="matrix(1 0 0 1 621.999 227.2734)"
                                        id="svgsecurity"
                                        class="st6 st7"
                                    >
                            {{ cardData.cvv ? cardData.cvv : '985' }}
                          </text>
                                    <g class="st8">
                            <text
                                transform="matrix(1 0 0 1 518.083 280.0879)"
                                class="st9 st6 st10"
                            >
                              security code
                            </text>
                          </g>
                                    <rect
                                        x="58.1"
                                        y="378.6"
                                        class="st11"
                                        width="375.5"
                                        height="13.5"
                                    />
                                    <rect
                                        x="58.1"
                                        y="405.6"
                                        class="st11"
                                        width="421.7"
                                        height="13.5"
                                    />
                                    <text
                                        transform="matrix(1 0 0 1 59.5073 228.6099)"
                                        class="st12 st13"
                                    >
                             {{ cardData.name ? cardData.name : 'John Doe' }}
                          </text>
                        </g>
                      </svg>
                        </div>
                    </div>
                </div>
                <div class="form-container">

                    <div class="field-container">
                        <label for="name" class="fs">Name</label>
                        <input maxlength="40" class="fs" type="text" v-model="cardData.name"
                               @focus="flipped = false"/>
                    </div>
                    <div class="field-container">
                        <label for="cardnumber" class="fs">Card Number</label>
                        <input
                            type="text"
                            inputmode="numeric"
                            class="fs"
                            @focus="flipped = false"
                            @keyup="updateCardNumber"
                            v-model="cardData.cardNumber"
                            v-mask="cardData.cardMask"
                        />
                        <svg
                            :ref="`ccicon-${alias}`"
                            class="ccicon"
                            width="750"
                            height="471"
                            viewBox="0 0 750 471"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        ></svg>
                    </div>
                    <div class="field-container">
                        <label class="fs" for="expirationdate">Expiration (mm/yy)</label>
                        <input
                            type="text"
                            inputmode="numeric"
                            class="fs"
                            v-model="cardData.expiration"
                            @focus="flipped = false"
                            @keyup="updateExpiration"
                            v-mask="'##/##'"
                        />
                    </div>
                    <div class="field-container">
                        <label class="fs" for="securitycode">Security Code</label>
                        <input
                            type="text"
                            inputmode="numeric"
                            class="fs"
                            @focus="flipped = true"
                            v-model="cardData.cvv"
                            v-mask="'####'"
                        />
                    </div>
                </div>
            </div>
            <div class="more-card-info" v-if="moreCardInfo">
                <label class="fs" for="securitycode">Street Address</label>
                <input
                    type="text"
                    class="fs"
                    v-model="cardData.address"
                />
                <label class="fs" for="securitycode">Town / City</label>
                <input
                    type="text"
                    class="fs"
                    v-model="cardData.town"
                />
                <label class="fs" for="securitycode">State / County</label>
                <input
                    type="text"
                    class="fs"
                    v-model="cardData.county"
                />
                <label class="fs" for="securitycode">Postcode / ZIP</label>
                <input
                    type="text"
                    class="fs"
                    v-model="cardData.zip"
                />
            </div>
            <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
            <div class="row-fs stretch">

                <div class="cont">
                    <div class="sign">{{ currencySign(currency) }}</div>
                    <input v-model="amount" ref="card-amount"/>
                    <select
                        class="card-input form-control input-fs-8"
                        v-model="currency"
                        :class="{activeSelect: activeSelect}"
                        @click.stop="activeSelect = !activeSelect"
                    >
                        <option v-for="(currency, i) in selectCurrencies" :key="i" :value="currency">{{ currency }}</option>
                    </select>
                </div>
                <button class="fs-small proceed" @click="makeDeposit">
                    {{ tokens.fullScreenDeposit.continue }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {MORE_CARD_INFO} from "@/common/config";
import {currencySign} from "@/common/helpers";

export default {
    props: ['tabActive', 'cardCurrencies', 'alias'],
    name: "CardBox",
    data() {
        return {
            currency: '',
            moreCardInfo: MORE_CARD_INFO,
            flipped: false,
            activeSelect: false,
            amount: null,
            selectCurrencies: null,
            cardData: {
                cardNumber: '',
                month: '',
                year: '',
                expiration: '',
                cvv: '',
                name: '',
                cardType: '',
                cardMask: '',
                cardStyle: 'grey',
                address: '',
                town: '',
                county: '',
                zip: '',
            },
            mask: [
                {
                    mask: '#### ###### #####',
                    regex: '^3[47]\\d{0,13}',
                    cardtype: 'american express'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
                    cardtype: 'discover'
                },
                {
                    mask: '#### ###### ####',
                    regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
                    cardtype: 'diners'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
                    cardtype: 'mastercard'
                },
                {
                    mask: '#### ###### #####',
                    regex: '^(?:2131|1800)\\d{0,11}',
                    cardtype: 'jcb15'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^(?:35\\d{0,2})\\d{0,12}',
                    cardtype: 'jcb'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
                    cardtype: 'maestro'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^4\\d{0,15}',
                    cardtype: 'visa'
                },
                {
                    mask: '#### #### #### ####',
                    regex: '^62\\d{0,14}',
                    cardtype: 'unionpay'
                },
                {
                    mask: '#### #### #### ####',
                    cardtype: 'Unknown'
                }
            ],
        }
    },
    methods: {
        currencySign,
        updateCardNumber(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) === -1) {
                e.preventDefault()
            }

            for (let i = 0; i < this.mask.length; i++) {
                this.cardData.cardMask = this.mask[i].mask

                let re = new RegExp(this.mask[i].regex)
                if (this.cardData.cardNumber.match(re)) {
                    this.cardData.cardType = this.mask[i].cardtype
                    break
                }
            }

            switch (this.cardData.cardType) {
                case 'american express':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.amex
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.amex_single
                    this.cardData.cardStyle = 'green'
                    break
                case 'visa':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.visa
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.visa_single
                    this.cardData.cardStyle = 'lime'
                    break
                case 'diners':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.diners
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.diners_single
                    this.cardData.cardStyle = 'orange'
                    break
                case 'discover':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.discover
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.discover_single
                    this.cardData.cardStyle = 'purple'
                    break
                case ('jcb' || 'jcb15'):
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.jcb
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.jcb_single
                    this.cardData.cardStyle = 'red'
                    break
                case 'maestro':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.maestro
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.maestro_single
                    this.cardData.cardStyle = 'yellow'
                    break
                case 'mastercard':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.mastercard
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.mastercard_single
                    this.cardData.cardStyle = 'lightblue'
                    break
                case 'unionpay':
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = this.creditCardIcons.unionpay
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = this.creditCardIcons.unionpay_single
                    this.cardData.cardStyle = 'cyan'
                    break
                default:
                    this.$refs[`ccicon-${this.tabActive}`].innerHTML = ''
                    this.$refs[`ccsingle-${this.tabActive}`].innerHTML = ''
                    this.cardData.cardStyle = 'grey'
                    break
            }
        },
        updateExpiration(e) {
            if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) === -1) {
                e.preventDefault()
            }

            this.cardData.month = this.cardData.expiration.slice(0, 2)
            this.cardData.year = this.cardData.expiration.slice(3, 5)
        },
        makeDeposit() {
            this.cardData.amount = this.amount
            this.cardData.from = 'card'
            this.cardData.currency = this.currency
            this.$emit('sendDeposit', this.cardData)
        },
    },
    computed: {
        ...mapGetters(['tokens','creditCardIcons','currencies'])
    },
    watch: {
        tabActive(val) {
            switch (val) {
                case 'cc-ext':
                case 'cc-ext2':
                case 'alwayspay_visa':
                case 'payonera':
                case 'alwayspay_master':
                    this.selectCurrencies = this.cardCurrencies.length ? this.cardCurrencies : this.currencies
                    this.currency = this.selectCurrencies.includes("USD") ? "USD" : this.selectCurrencies[0]
                    break;
            }
        }
    }
}
</script>

<style scoped>
.row-fs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    min-width: 300px;
}

.deposit .row-fs.stretch {
    grid-template-columns: repeat(2, max-content);
}

.aspin-design .deposit .row-fs.stretch {
    grid-template-columns: repeat(2, 1fr);
}

.amounts .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.aspin-design button {
    height: 100%;
}


.address input {
    flex: 1 1 auto;
}


.amounts .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.amount {
    margin: 15px 0;
}

.cont {
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    height: 49px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    position: relative;
}

.row-fs .cont {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    place-content: center;
}

.aspin-design .amounts .item,
.aspin-design .row-fs .cont {
    border: 1px solid #34384C;
    border-radius: 50px;
    height: 45px;
    align-items: baseline;
}

.aspin-design .row-fs .cont {
    padding: 0 0 0 20px;
}

.cont .amount {
    margin: 3px 0 3px 5px;
}

.cont input {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    color: var(--white-to-black);
    padding-left: 10px;
    width: 100%;
    margin-top: 0;
}

.cont .sign {
    font-size: 1.5em;
    font-weight: 500;
}

.bank-details div {
    margin-bottom: 8px;
}

.bank-details .bank-val {
    color: var(--white);
    margin-left: 5px;
}

.card-input {
    position: absolute;
    background: var(--fs);
    border: 1px solid var(--fs-border);
    color: white;
    border-radius: 5px;
    font-size: 1.3em;
    padding: 5px;
    text-align: center;
    height: 30px;
    caret-color: white !important;
    top: 50%;
    transform: translate(-50%, -50%);
    right: 0;
}

.form-container .field-container:first-of-type {
    grid-area: name;
}

.form-container input {
    border: 1px solid var(--fs-border);
}

.form-container .field-container:nth-of-type(2) {
    grid-area: number;
}

.form-container .field-container:nth-of-type(3) {
    grid-area: expiration;
}

.form-container .field-container:nth-of-type(4) {
    grid-area: security;
}

.field-container input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.field-container {
    position: relative;
}

.form-container {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: auto auto;
    gap: 30px;
    grid-template-areas: "name name""number number""expiration security";
    padding: 0;
    color: #707070;
    max-width: 400px;
}

.card label {
    font-weight: 100;
}

.card input {
    width: 100%;
    color: #fff;
    border-radius: 10px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    padding: 15px 10px;
    position: relative;
}

.ccicon {
    height: 28px;
    position: absolute;
    right: 6px;
    top: calc(50% - 5px);
    width: 60px;
}

.container {
    width: 100%;
    max-width: 400px;
    max-height: 251px;
    height: 54vw;
    padding: 20px 0;
}

.ccsingle {
    position: absolute;
    right: 15px;
    top: 20px;
}

.ccsingle > * {
    color: red;
    position: absolute;
}

.creditcard svg.cardfront,
.creditcard svg.cardback {
    width: 100%;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
    border-radius: 22px;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
    -webkit-transition: fill .5s;
    transition: fill .5s;
}

.creditcard .lightblue {
    fill: #03A9F4;
}

.creditcard .lightbluedark {
    fill: #0288D1;
}

.creditcard .red {
    fill: #ef5350;
}

.creditcard .reddark {
    fill: #d32f2f;
}

.creditcard .purple {
    fill: #ab47bc;
}

.creditcard .purpledark {
    fill: #7b1fa2;
}

.creditcard .cyan {
    fill: #26c6da;
}

.creditcard .cyandark {
    fill: #0097a7;
}

.creditcard .green {
    fill: #66bb6a;
}

.creditcard .greendark {
    fill: #388e3c;
}

.creditcard .lime {
    fill: #d4e157;
}

.creditcard .limedark {
    fill: #afb42b;
}

.creditcard .yellow {
    fill: #ffeb3b;
}

.creditcard .yellowdark {
    fill: #f9a825;
}

.creditcard .orange {
    fill: #ff9800;
}

.creditcard .orangedark {
    fill: #ef6c00;
}

.creditcard .grey {
    fill: #bdbdbd;
}

.creditcard .greydark {
    fill: #616161;
}

/* FRONT OF CARD */
.svgname {
    text-transform: uppercase;
}

.cardfront .st2 {
    fill: #FFFFFF;
}

.cardfront .st3 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
}

.cardfront .st4 {
    font-size: 54.7817px;
}

.cardfront .st5 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
}

.cardfront .st6 {
    font-size: 33.1112px;
}

.cardfront .st7 {
    opacity: 0.6;
    fill: #FFFFFF;
}

.cardfront .st8 {
    font-size: 24px;
}

.cardfront .st9 {
    font-size: 36.5498px;
}

.cardfront .st10 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 300;
}

.cardfront .st11 {
    font-size: 16.1716px;
}

.cardfront .st12 {
    fill: #4C4C4C;
}

/* BACK OF CARD */
.cardback .st0 {
    fill: none;
    stroke: #0F0F0F;
    stroke-miterlimit: 10;
}

.cardback .st2 {
    fill: #111111;
}

.cardback .st3 {
    fill: #F2F2F2;
}

.cardback .st4 {
    fill: #D8D2DB;
}

.cardback .st5 {
    fill: #C4C4C4;
}

.cardback .st6 {
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
}

.cardback .st7 {
    font-size: 27px;
}

.cardback .st8 {
    opacity: 0.6;
}

.cardback .st9 {
    fill: #FFFFFF;
}

.cardback .st10 {
    font-size: 24px;
}

.cardback .st11 {
    fill: #EAEAEA;
}

.cardback .st12 {
    font-family: 'Rock Salt', cursive;
}

.cardback .st13 {
    font-size: 37.769px;
}

/* FLIP ANIMATION */
.container {
    perspective: 1000px;
}

.creditcard {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: all ease-in-out 0.6s;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
}

.creditcard .front,
.creditcard .back {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    color: #47525d;
    max-width: 400px;
}

.creditcard .back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.creditcard.flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.more-card-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media (min-width: 1921px) {
    .amounts .card {
        gap: 1vw;
    }

    .card input {
        border-radius: 0.5vw;
        border: 0.1vw solid var(--fs-border);
        height: 3vw;
        padding: 0 0.5vw;
    }

    .ccsingle {
        right: .7vw;
        top: 1vw;
    }

    .ccicon {
        height: 1.5vw;
        right: .3vw;
        top: calc(50% - .2vw);
        width: 3.125vw;
    }

    .container {
        max-width: 20vw;
        padding: 1vw 0;
        max-height: 13vw;
        height: 26vw;
        perspective: 52vw;
    }

    .creditcard .front,
    .creditcard .back {
        max-width: 20vw;
    }

    .creditcard svg.cardfront, .creditcard svg.cardback {
        border-radius: 1vw;
        box-shadow: .1vw .3vw .3vw 0 black;
    }

    .form-container {
        gap: 1.5vw;
        padding: 0;
        max-width: 20vw;
    }

    .creditcard {
        max-width: 20vw;
    }

    .cardfront .st4,
    .cardfront .st6,
    .cardfront .st8,
    .cardfront .st9 {
        font-size: 1vw;
    }

    .cardfront .st11 {
        font-size: .6vw;
    }
}

@media (max-width: 1378px) {
    .container {
        padding: 20px 30px;
    }

    .amounts .card label {
        display: inline-block;
    }
}

@media (min-width: 1000px) {
    .row-fs {
        gap: 1vw;
    }

    .banking-details > div {
        font-size: 1vw;
        margin-top: 1vw;
    }

    .aspin-design .amounts .item,
    .aspin-design .row-fs .cont {
        border: 0.1vw solid #34384C;
        border-radius: 10vw;
        height: 3.5vw;
    }

    .aspin-design .row-fs .cont {
        padding: 0 0 0 2.4vw;
    }

    .cont {
        border-radius: .5vw;
        border: .1vw solid var(--fs-border);
        background: var(--background-dark-mid);
        height: 3vw;
        padding: 0 .5vw;
        position: relative;
    }

    .proceed {
        height: auto;
    }

    .cont .amount {
        margin: .15vw 0 .15vw .25vw;
    }

    .cont .sign {
        bottom: 0.55vw;
        font-size: 1.5vw;
    }

    .card-input {
        position: absolute;
        border: .1vw solid var(--fs-border);
        border-radius: .2vw;
        font-size: 1vw;
        padding: 0.15vw 0.3vw;
    }

    label.attach {
        margin-right: .5vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .4vw .625vw;
        font-size: .7vw;
    }

    .over::after {
        top: .15vw;
        left: .15vw;
        right: .15vw;
        bottom: .15vw;
        border: .4vw dashed white;
    }

    .amount {
        font-size: 1vw;
        margin: .7vw 0;
    }

    .cont input {
        font-size: 1.3vw;
        height: 100%;
        padding-left: .3vw;
    }

    i {
        font-size: 1.25vw;
    }

    .card-input {
        border: .1vw solid var(--fs-border);
        border-radius: .15vw;
        font-size: 1vw;
        padding: .2vw;
        height: 2vw;
    }

    .form-container input {
        border: .1vw solid var(--fs-border);
    }
}

@media (max-width: 1000px) {
    .cont .sign {
        bottom: 12px;
    }

    .amounts .item {
        margin: 0 0 25px 0;
    }

    .row-fs {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        min-width: 15vw;
    }

    .proceed {
        height: 49px;
    }

    .amounts {
        margin-left: 0 !important;
        width: 100%;
    }

    .amounts .col {
        flex: 1 1 calc(50% - 10px)
    }

    .amounts .col:first-child {
        margin-right: 0;
    }

    .amounts .item {
        width: auto;
    }

    .proceed, .cont {
        width: 100%;
    }

    .amounts .card {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 500px) {
    .card-input {
        border-radius: 2px;
        font-size: 1em;
        text-align: center;
        height: 24px;
    }
}
</style>
