export const cardPspList = [{
    alias: "payonera",
    currencies: ["USD", "CAD"]
}, {
    alias: "bmopay",
    currencies: ["USD", "EUR"]
}, {
    alias: "cc-ext",
    currencies: []
}, {
    alias: "pinpay",
    currencies: ["USD", "EUR", "KGS", "RUB"]
}, {
    alias: "alwayspay_visa",
    currencies: ["USD"]
}, {
    alias: "alwayspay_master",
    currencies: ["USD"]
}]
