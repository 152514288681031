<template>
    <div :class="{hide: !modals.profitCalculator}"
         class="modal-wrapper"
         @mousedown.stop="hideModal">
        <div class="modal" @mousedown.stop>
            <div class="close" @click="hideModal">
                <i class="material-icons">close</i>
            </div>
            <div class="scroll-container">
                <div class="left">
                    <div class="buttons">
                        <button :class="[!orderType ? 'active' : '']" class="button-buy" @click.stop="switchTo('buy')">
                            {{ tokens.profitCalculatorModal.buy }}
                        </button>
                        <button :class="[orderType ? 'active' : '']" class="button-sell" @click.stop="switchTo('sell')">
                            {{ tokens.profitCalculatorModal.sell }}
                        </button>
                    </div>
                    <div class="calculate">
                        <div class="inner">

                            <div :class="{transparent: inputContainers.volumeTransparent}"
                                 class="volume-with-buttons value-container"
                                 @click="focus('volume')"
                            >
                                <div class="subtitle-input">
                                    <div class="value">{{ tokens.profitCalculatorModal.volume }}</div>
                                    <input ref="volumeInput" class="value" inputmode="decimal"
                                           type="number"
                                           @keypress="validateInput"
                                           v-model="volume"
                                    />
                                </div>
                            </div>

                            <div :class="{transparent: inputContainers.entryPriceTransparent}"
                                 class="volume-with-buttons value-container"
                                 @click="focus('entryPrice')"
                            >
                                <div class="subtitle-input">
                                    <div class="value">{{ tokens.profitCalculatorModal.entryPrice }}</div>
                                    <input ref="entryPriceInput" class="value" inputmode="decimal"
                                           type="number"
                                           @keypress="validateInput"
                                           v-model="entryPrice"
                                    />
                                </div>
                            </div>

                            <div :class="{transparent: inputContainers.takeProfitTransparent}"
                                 class="volume-with-buttons value-container"
                                 @click="focus('takeProfit')"
                            >
                                <div class="subtitle-input">
                                    <div class="value">{{ tokens.profitCalculatorModal.takeProfit }}</div>
                                    <input ref="takeProfitInput" class="value" inputmode="decimal"
                                           type="number"
                                           @keypress="validateInput"
                                           v-model="takeProfit"
                                    />
                                </div>
                            </div>

                            <div :class="{transparent: inputContainers.stopLossTransparent}"
                                 class="volume-with-buttons value-container"
                                 @click="focus('stopLoss')"
                            >
                                <div class="subtitle-input">
                                    <div class="value">{{ tokens.profitCalculatorModal.stopLoss }}</div>
                                    <input ref="stopLossInput" class="value" inputmode="decimal"
                                           type="number"
                                           @keypress="validateInput"
                                           v-model="stopLoss"
                                    />
                                </div>
                            </div>
                            <span class="max-borrow"> {{
                                    tokens.profitCalculatorModal.maxPosition
                                }}: {{ Math.abs(Number(maxPosition.toFixed(2))) }} {{ symbol.alias }}</span>
                        </div>
                        <button class="calculate-btn" @click="calculate">{{
                                tokens.profitCalculatorModal.calculate
                            }}
                        </button>
                    </div>
                </div>
                <div class="right">
                    <div class="inner">
                        <div>
                            <h2 class="title">{{ tokens.profitCalculatorModal.calculationsFor }} {{ symbol.alias }}</h2>
                            <ul>
                                <li v-show="showLeverage==1">
                                    <div>
                                        <h3 class="pnl">{{ tokens.profitCalculatorModal.leverage }}</h3>
                                        <div class="value">1:{{ results.leverage }}</div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 class="pnl">{{ tokens.profitCalculatorModal.requiredMargin }}</h3>
                                        <div class="value">{{ results.margin }} <span>{{
                                                activeAccount.currency
                                            }}</span></div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 class="pnl">{{ tokens.profitCalculatorModal.profitFromTP }}</h3>
                                        <div class="value">{{ results.profit }} <span>{{
                                                activeAccount.currency
                                            }}</span></div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 class="pnl">{{ tokens.profitCalculatorModal.lossFromSL }}</h3>
                                        <div class="value">{{ Math.abs(results.loss) }} <span>{{
                                                activeAccount.currency
                                            }}</span></div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 class="pnl">{{ tokens.profitCalculatorModal.roe }}</h3>
                                        <div class="value">{{ results.roe > 0 ? '+' : '' }}{{ results.roe.toFixed(2) }}
                                            <span>%</span></div>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 class="pnl">PIP</h3>
                                        <div class="value">{{ results.pip }} <span>{{ activeAccount.currency }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL} from '@/store/mutations.type'
import {currencySign} from '@/common/helpers'
import {SHOW_LEVERAGE} from "@/common/config"

export default {
    name: 'modalProfitCalculator',
    data() {
        return {
            showLeverage: SHOW_LEVERAGE,
            volume: '',
            entryPrice: '',
            stopLoss: '',
            takeProfit: '',
            orderType: false,

            inputContainers: {
                volumeTransparent: false,
                entryPriceTransparent: false,
                stopLossTransparent: false,
                takeProfitTransparent: false,
            },

            results: {
                leverage: 1,
                margin: 0,
                profit: 0,
                loss: 0,
                roe: 0,
                pip: 0,
            }
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                profitCalculator: false
            })
        },
        switchTo(typeString) {
            if (typeString === 'buy') {
                this.orderType = false;
            }
            if (typeString === 'sell') {
                this.orderType = true;
            }
        },
        focus(ref) {
            for (let n in this.inputContainers) {
                this.inputContainers[n] = false
            }
            this.inputContainers[ref + 'Transparent'] = true
            this.$refs[ref + 'Input'].focus()
        },

        validateInput(e) {
            if (e.key === 'e') {
                e.preventDefault()
            }

            if ((e.key === '.' || e.key === ',') && (e.target.value.indexOf('.') !== -1 || e.target.value.indexOf(',') !== -1)) {
                e.preventDefault()
            }
        },

        calculate() {
            this.results.leverage = this.symbol.leverage_default || this.activeAccount.leverage

            let margin = this.volume / this.results.leverage,
                point = this.volume * Math.pow(0.1, this.symbol.precision)

            let baseRate = 1, baseCur = this.symbol.base_currency,
                accRate = 1, accCur = this.activeAccount.currency

            if (this.symbols["USD" + baseCur] !== undefined) {
                baseRate = this.symbols["USD" + baseCur].quote.bid
            } else if (this.symbols[baseCur + "USD"] !== undefined) {
                baseRate = 1 / this.symbols[baseCur + 'USD'].quote.bid
            }

            if (this.symbols["USD" + accCur] !== undefined) {
                accRate = this.symbols["USD" + accCur].quote.bid
            } else if (this.symbols[accCur + "USD"] !== undefined) {
                accRate = 1 / this.symbols[accCur + 'USD'].quote.bid
            }

            let marginRate = accRate / baseRate
            point *= marginRate
            let quoteBid = this.symbol?.quote?.bid || 1

            this.results.margin = (margin * quoteBid * marginRate).toFixed(this.activeAccount.precision) || 0
            this.results.profit = (point * (this.orderType ? this.entryPrice - this.takeProfit : this.takeProfit - this.entryPrice) * Math.pow(10, this.symbol.precision)).toFixed(this.activeAccount.precision) || 0
            this.results.loss = (point * (this.orderType ? this.entryPrice - this.stopLoss : this.stopLoss - this.entryPrice) * Math.pow(10, this.symbol.precision)).toFixed(this.activeAccount.precision) || 0
            this.results.roe = 100 * this.results.profit / this.results.margin || 0
            this.results.pip = Number(point.toFixed(this.activeAccount.precision) || 0)
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'chartActive', 'charts', 'activeAccount', 'tokens']),

        PNL() {
            return 5 * (this.volume - this.entryPrice)
        },
        ROE() {
            return ((this.entryPrice / 1.1) * 0.5) - (this.volume + this.takeProfit - this.stopLoss) || 0
        },

        maxPosition() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                equity: 0,
                margin: 0
            }

            if (!this.activeAccount.id) {
                return 0
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            for (let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                b.margin += o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision)) : 0
            }

            let freeMargin = b.balance + b.credit + b.profit - b.margin,
                leverage = this.symbol.leverage_default || this.activeAccount.leverage,
                position = freeMargin * leverage,
                quoteBid = this.symbol?.quote?.bid || 1

            if (this.symbol.base_currency === this.activeAccount.currency) {
                position /= quoteBid
            } else if (this.symbols[this.symbol.base_currency + this.activeAccount.currency] !== undefined) {
                position /= quoteBid * this.symbols[this.symbol.base_currency + this.activeAccount.currency].quote.bid
            } else if (this.symbols[this.activeAccount.currency + this.symbol.base_currency] !== undefined) {
                position /= quoteBid / this.symbols[this.activeAccount.currency + this.symbol.base_currency].quote.bid
            }

            if (this.symbol.lot_step && this.symbol.lot) {
                let step = (this.symbol.lot_step * this.symbol.lot)
                position = Math.floor(position / step) * step
            }

            return position
        },

        symbol() {
            let symbol = {
                alias: ''
            }

            if (!this.chartActive || !this.charts[this.chartActive]) {
                return symbol
            }
            return this.symbols[this.charts[this.chartActive].symbol] || symbol
        }
    },
    watch: {
        modals: {
            deep: true,
            handler(val) {
                if (val.profitCalculator) {
                    this.orderType = false

                    this.volume = this.symbol.lot
                    this.entryPrice = this.symbol?.quote?.bid || 1
                    this.stopLoss = (this.entryPrice - 100 * Math.pow(0.1, this.symbol.precision)).toFixed(this.symbol.precision)
                    this.takeProfit = (this.entryPrice + 100 * Math.pow(0.1, this.symbol.precision)).toFixed(this.symbol.precision)

                    this.calculate()
                }
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    /*grid-template-columns: repeat(1fr);*/
    background: var(--background-dark);
    z-index: 200;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 110px;
    width: 600px;
    height: 400px;
    padding: 20px;
}

.scroll-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.close {
    background-color: var(--background);
    border-radius: 50%;
    position: absolute;
    left: -20px;
    top: -20px;
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease-in-out 0.3s;
}

.close i {
    font-size: 2.5em;
    transition: color ease-in-out 0.3s;
}

.close:hover {
    background-color: var(--background-light);
}

.close:hover i {
    color: var(--white)
}

.buttons {
    background-color: #15152a;
    border-radius: 10px;
    margin-bottom: 20px;
}

.buttons .button-buy,
.buttons .button-sell {
    width: 50%;
    font-weight: 500;
}

.buttons .button-buy.active {
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
}

.buttons .button-sell.active {
    background-image: linear-gradient(315deg, #eb4511 0%, #b02e0c 74%);
}

.value-container {
    height: 40px;
    background-color: var(--aspin-background-light);
    border: 2px solid var(--border);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    transition: background-color ease-in-out 0.3s;
    flex: 1 1 auto;
    margin-bottom: 10px;
    justify-content: center;
    border-radius: 3px;
}

.subtitle-input {
    display: grid;
    grid-auto-columns: auto 1fr;
    padding: 5px;
    position: relative;
    place-items: center center;
}

.value-container:hover .value {
    color: var(--white-to-black);
}

.subtitle-input div {
    position: absolute;
    top: 50%;
    left: 7px;
    transform: translateY(-50%);
    color: var(--font);
    font-weight: 400;
    font-size: 11px;
}

.value-container.transparent {
    border: 2px solid var(--background-light);
    background-color: transparent;
}

.title {
    color: var(--white-to-black);
    font-weight: 400;
}

.left {
    width: 45%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
}

.max-borrow {
    display: inline-block;
    margin: 0 0 0 2px;
    font-size: 1.1em;
    font-weight: 700;
}

.calculate-btn {
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    font-weight: bold;
}

.calculate {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.right {
    width: 55%;
    padding: 20px;
    background: var(--aspin-background-light);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right ul li {
    list-style: none;
    margin-top: 15px;
}

.right ul li:first-child {
    margin-top: 25px
}

.right ul li div {
    display: flex;
    justify-content: space-between;
}

.right ul h3,
.right div.value {
    font-weight: normal;
}

input.value {
    background-color: transparent;
    border: none;
    /*width: 40%;*/
    cursor: pointer;
    font-size: 1.15em;
    margin-left: 40px;
    text-align: end;
}

input::placeholder {
    font-size: 14px;
}

.value {
    color: var(--white-to-black);
    font-weight: bold;
    font-size: 1.17em;
}

.value span {
    color: var(--font);
    margin-left: 5px;
}

/* modifiers */

.value-container:hover span {
    color: var(--white);
}

.value-container.transparent {
    border: 2px solid var(--background-light);
    background-color: transparent;
}

.type-select-options .item {
    border-bottom: 1px solid var(--border);
    width: 100%;
    padding: 6px 10px;
    color: var(--white);
    font-weight: 400;
    cursor: pointer;
}

.type-select-options .item:last-child {
    border-bottom: none;
}

.type-select-options .item.active, .type-select-options .item:hover {
    background: var(--border);
}

.type-select-options .item span {
    margin-right: 10px;
}


/* leave the type=number, but hide the controls: */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media (max-width: 768px) {
    .modal {
        width: 90%;
        height: 80%;
    }

    .scroll-container {
        flex-direction: column;
        overflow-y: auto;
    }

    .left, .right {
        width: 100%;
    }

    .left {
        margin-right: 0;
    }

    .right {
        margin-top: 20px;
    }

}

</style>
