<template>
    <div class="widget">
        <div class="title">{{ tokens.widgetOrders.activeOrders }} <i class="material-icons close" v-on:click="hideModal('orders')">close</i> </div>
        <div class="tabs">
            <div class="tab-item" :class="{active: tabActive === 'active'}" @click="tabActive = 'active'">
                <span>{{ tokens.widgetOrders.active.toUpperCase() }}</span>
            </div>
            <div class="tab-item" :class="{active: tabActive === 'pending'}" @click="tabActive = 'pending'">
                <span>{{ tokens.widgetOrders.pending.toUpperCase() }}</span>
            </div>
        </div>
        <div class="content scroll">
            <select v-model="marketFilter" class="form-control">
                <option value="">{{ tokens.widgetOrders.allPositions }}</option>
                <option value="forex">{{ tokens.markets.forex }}</option>
                <option value="stocks">{{ tokens.markets.stocks }}</option>
                <option value="indices">{{ tokens.markets.indices }}</option>
                <option value="commodities">{{ tokens.markets.commodities }}</option>
                <option value="crypto">{{ tokens.markets.crypto }}</option>
                <option value="metals">{{ tokens.markets.metals }}</option>
                <option value="nft">{{ tokens.markets.nft }}</option>
            </select>
            <div class="no-orders" :class="computeNoOrdersVisibility">
                <i class="material-icons">import_export</i>
                <span>{{ tokens.widgetOrders.noPositionsLine1 }}<br/>{{ tokens.widgetOrders.noPositionsLine2 }}</span>
            </div>
            <div class="col" :class="{hide: tabActive !== 'active'}">
                <div class="order-item"
                     v-for="(order, i) in orders"
                     :key="order.id"
                     :class="{
                             active: detailsOrder === order.id,
                             hide: marketFilter && (!symbols[order.symbol] || symbols[order.symbol].market !== marketFilter)
                         }"
                >
                    <div class="row">
                        <div class="col">
                            <div class="name">
                                <div class="row" @click="changeSymbol(order.symbol)">
<!--                                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                                    <div class="symbol" :class="order.symbol ? 'symbol-'+order.symbol.toUpperCase() : '' "/>
                                    <span>{{ symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol }}</span>
                                </div>
                                <div class="profit" :class="order.profit < 0 ? 'red' : 'green'">
                                    {{ (0 > order.profit ? '-' : (order.profit > 0 ? '+' : ''))+currencySign(activeAccount.currency)+Math.abs(order.profit).toFixed(activeAccount ? activeAccount.precision : 2) }}
                                </div>
                            </div>
                            <div class="smaller">
                                <div class="row pointer" v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    {{ tokens.widgetOrders.show }} {{ detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less }} <i class="material-icons">{{ detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                                </div>
                                <div class="pct" :class="order.profit < 0 ? 'red' : 'green'">
                                    {{ (order.profit > 0 ? '+' : (0 > order.profit ? '-' : '' ))+(symbols[order.symbol] ? (Math.abs(symbols[order.symbol].quote[order.type % 2 ? 'ask' : 'bid'] - order.price_open) / order.price_open * 100) : 0).toFixed(2)+'%' }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="details" :class="{hide: detailsOrder !== order.id}">
                        <button class="close-position" @click="showClosePositionModal(order.id, i)">{{ tokens.widgetOrders.closePosition }}</button>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.purchaseTime }}</span>
                            <span>{{ new Date(order.time_open * 1000).toLocaleString() }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceOpen }}</span>
                            <span>{{ order.price_open.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceSl }}</span>
                            <div class="row">
                                <span>{{ order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'}}</span>
                                <i class="material-icons edit" @click="showAutoCloseModal($event, order.id, i)">edit</i>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceTp }}</span>
                            <div class="row">
                                <span>{{ order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'}}</span>
                                <i class="material-icons edit" @click="showAutoCloseModal($event, order.id, i)">edit</i>
                            </div>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.type }}</span>
                            <span :class="order.type ? 'red' : 'green'">
                                    {{order.type ? tokens.closePositionModal.sell : tokens.closePositionModal.buy}} <i class="material-icons">{{order.type ? 'arrow_drop_down' : 'arrow_drop_up'}}</i>
                                </span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.volume }}</span>
                            <span>{{ order.volume }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.margin }}</span>
                            <span>{{ currencySign(activeAccount.currency)+order.easy_amount.toFixed(activeAccount.precision) }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.pnl }}</span>
                            <span>{{ (order.profit > 0 ? '+' : (0 > order.profit ? '-' : ''))+currencySign(activeAccount.currency)+Math.abs(order.profit).toFixed(activeAccount.precision) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col" :class="{hide: tabActive !== 'pending'}">
                <div class="order-item"
                     v-for="(order, i) in pendingOrders"
                     :key="order.id"
                     :class="{
                             active: detailsOrder === order.id,
                             hide: marketFilter && symbols[order.symbol].market !== marketFilter
                         }"
                >
                    <div class="row">
                        <div class="col">
                            <div class="name">
                                <div class="row" @click="changeSymbol(order.symbol)">
<!--                                    <img :src="staticBase+order.symbol.toUpperCase()+'.png'" />-->
                                    <div class="symbol" :class="'symbol-'+order.symbol.toUpperCase()" />
                                    <span>{{ symbols[order.symbol] ? symbols[order.symbol].alias : order.symbol }}</span>
                                </div>
                                <div class="profit" :class="order.type % 2 ? 'red' : 'green'">
                                    {{ tokens.orderTypes[order.type] }}
                                </div>
                            </div>
                            <div class="smaller">
                                <div class="row pointer" v-on:click="detailsOrder = detailsOrder === order.id ? 0 : order.id">
                                    {{ tokens.widgetOrders.show }} {{ detailsOrder !== order.id ? tokens.widgetOrders.more : tokens.widgetOrders.less }} <i class="material-icons">{{ detailsOrder !== order.id ? 'arrow_drop_down' : 'arrow_drop_up' }}</i>
                                </div>
                                <div class="pct" :class="order.type % 2 ? 'red' : 'green'">
                                    {{ order.price_open.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="details" :class="{hide: detailsOrder !== order.id}">
                        <button class="close-position" @click="showClosePositionModal(order.id, i)">{{ tokens.widgetOrders.cancelOrder }}</button>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceOpen }}</span>
                            <span>{{ order.price_open.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) }}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceSl }}</span>
                            <span>{{ order.sl ? order.sl.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'}}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.priceTp }}</span>
                            <span>{{ order.tp ? order.tp.toFixed(symbols[order.symbol] ? symbols[order.symbol].precision : 5) : '-'}}</span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.orderType }}</span>
                            <span :class="order.type % 2 ? 'red' : 'green'">
                                    {{ tokens.orderTypes[order.type] }} <i class="material-icons">{{order.type % 2 ? 'arrow_drop_down' : 'arrow_drop_up'}}</i>
                                </span>
                        </div>
                        <div class="item">
                            <span>{{ tokens.widgetOrders.volume }}</span>
                            <span>{{ order.volume }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    MODAL,
    MODAL_AUTOCLOSE,
    MODAL_CLOSE_POSITION,
    MODAL_MENU,
    NOTIFICATION_ERROR,
    SYMBOL_ACTIVE_UPDATE
} from '@/store/mutations.type'
import {mapGetters} from 'vuex'
import {currencySign, isMobile} from '@/common/helpers'

export default {
    name: 'OpenOrders',
    data() {
        return {
            tabActive: 'active',
            marketFilter: '',
            detailsOrder: 0
        }
    },
    methods: {
        hideModal(widget) {
            let n = JSON.parse(JSON.stringify(this.modalMenu))

            n[widget] = !n[widget]

            this.$store.commit(MODAL_MENU, n)

            let hideMenuModal = true

            for(let i in n) {
                if (n[i]) {
                    hideMenuModal = false
                    break
                }
            }

            if (hideMenuModal) {
                this.$store.commit(MODAL, {
                    menu: false,
                    sideMenu: !isMobile()
                })
            }
        },
        showClosePositionModal(id, index) {
            if (!this.activeAccount.enable) {
                this.$store.commit(NOTIFICATION_ERROR, 'TRADING_DISABLED')
                return
            }
            console.log('close', index)
            console.log('id', id)

            this.$store.commit(MODAL_CLOSE_POSITION, {
                activeOrderId: id,
                index: index
            })
            this.$store.commit(MODAL, {
                closePosition: true
            })
        },
        showAutoCloseModal(e, id, item) {
            let rect = e.target.getBoundingClientRect()

            this.$store.commit(MODAL_AUTOCLOSE, {
                left: rect.left + 200,
                top: rect.top - 100,
                symbolActive: null,
                orderActive: id,
                tp: this.activeAccount.orders[item].tp || '',
                tpType: '=',
                sl: this.activeAccount.orders[item].sl || '',
                slType: '=',
                item: parseInt(item)
            })

            this.$store.commit(MODAL, {
                autoClose: true
            })
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.chartActive
            })
        },
        currencySign
    },
    computed: {
        ...mapGetters(['modals', 'symbols', 'activeAccount', 'tokens', 'chartActive', 'modalMenu','staticBase']),
        orders() {
            let orders = []

            if (!this.activeAccount.orders) {
                return orders
            }

            for(let n in this.activeAccount.orders) {
                let order = this.activeAccount.orders[n]

                if (order.type > 1) {
                    continue
                }

                if (!this.symbols[order.symbol]) {
                    order.profit = 0
                } else {
                    order.profit = (order.point * (order.type && this.symbols[order.symbol] ? order.price_open - this.symbols[order.symbol].quote.ask : this.symbols[order.symbol].quote.bid - order.price_open) * Math.pow(10, this.symbols[order.symbol].precision) + order.swap + order.commission)
                }

                order.easy_amount = order.easy_amount || order.margin

                orders.push(order)
            }

            return orders
        },
        pendingOrders() {
            let orders = []

            if (!this.activeAccount.orders) {
                return orders
            }

            for(let n in this.activeAccount.orders) {
                let order = this.activeAccount.orders[n]

                if (order.type < 2) {
                    continue
                }

                order.profit = 0
                order.easy_amount = order.easy_amount || order.margin

                orders.push(order)
            }

            return orders
        },
        computeNoOrdersVisibility() {
            if(this.orders.length > 0 || this.pendingOrders.length > 0) {
                return 'hide'
            } else {
                return ''
            }
        }
    }
}
</script>

<style scoped>
    .tabs {
        display: flex;
        align-items: flex-end;
        margin: 10px;
    }

    .tabs .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        font-weight: 500;
        font-size: 1.1em;
        color: var(--white-to-black);
        border-bottom: 1px solid transparent;
        padding: 5px 0 10px;
        cursor: pointer;
    }

    .tabs .tab-item.active {
        color: var(--brand);
        border-bottom-color: var(--brand);
    }

    .tabs span {
        width: 100%;
        text-align: center;
        padding: 0 5px;
    }

    .tabs .tab-item:first-child span {
        border-right: 1px solid var(--border);
    }

    select {
        margin: 5px 0 15px;
    }

    .no-orders {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 15px
    }

    .no-orders i {
        font-size: 60px;
    }

    .no-orders span {
        font-size: 1.2em;
        text-align: center;
    }

    .order-item {
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--border);
        padding: 15px;
    }

    .order-item.active {
        border: 2px solid var(--border);
    }

    .order-item .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 3px;
        color: var(--white-to-black)
    }

    .order-item .name img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
    }

    .order-item .symbol {
        zoom: 0.25;
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        margin-right: 20px;
    }

    @-moz-document url-prefix() {
        .order-item .symbol {
            -moz-transform: scale(0.25);
            -moz-transform-origin: 50% 50%;
            position: absolute;
            left: 0;
        }

        .order-item .name .row:first-child span {
            margin-left: 22px;
        }
    }

    .order-item .col {
        flex: 1 1 auto;
    }

    .order-item .smaller {
        font-size: 0.9em;
        display: flex;
        align-items: center;
        font-weight: 400;
        justify-content: space-between;
    }

    .order-item .smaller i {
        font-size: 18px;
    }

    .order-item .edit {
        font-size: 1em;
        color: var(--white);
        cursor: pointer;
        margin-left: 5px;
    }

    .details {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 15px;
    }

    .details .close-position {
        margin-bottom: 10px;
        padding: 4px;
    }

    .details .item {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        background-color: inherit;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 8px;
        overflow: initial;
    }

    .details .item span:last-child {
        color: var(--white-to-black);
        display: flex;
        align-items: center;
    }

    .details .item span:last-child i {
        font-size: 16px;
    }

    .symbol {
        cursor: pointer;
    }
</style>
