<template>
    <div class="address" :class="{hide: tabActive !== alias}">
        <div class="address-generated"
             v-show="!address">
            {{ tokens.fullScreenDeposit.addWalletAddress }}
        </div>

        <div class="address-content"
             v-show="address">
            <div class="form-row qrcode">

                <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                <div class="qr-new">
                    <QrcodeVue
                        :value="address"
                        :size="150"/>
                </div>
            </div>
            <div class="form-row nopad">
                <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
            </div>
            <div class="qr-copy">
                <input readonly
                       :value="address"
                       class="form-control fs"/>
                <button class="copy fs"
                        @click="copyAddress(address)">
                    {{ tokens.fullScreenDeposit.copy }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {manualPspList} from "@/common/manual-psp-list"
import {mapGetters} from "vuex"
import QrcodeVue from 'qrcode.vue'
import {NOTIFICATION} from "@/store/mutations.type"

export default {
    name: "ManualPsp",
    props: ["alias", "address","tabActive"],
    components: {QrcodeVue},
    data() {
        return {
            manualPsps: manualPspList,
        }
    },
    computed: {
        ...mapGetters(['pspList', 'tokens'])
    },
    methods: {
        copyAddress(address) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Address copied to clipboard')
                })
                .catch(() => {})
        },
    }
}
</script>

<style scoped>

</style>
