<template>
    <div class="modal-wrapper" :class="{hide: !modals.register, newDesign: newDesign, 'aspin-modal-design': aspinDesign}">
        <div class="modal">
            <form class="modal-box">
                <a v-if="aspinDesign"
                   :href="website !== '0' ? website : '#'"
                   :target="website !== '0' ? '_blank' : ''"
                   :style="website !== '0' ? {} : {cursor:'default'}"
                    class="aspin-logo"
                >
                    <img :src="logoComputed" :style="{height: logo.height}" :class="{hide: !logo.url}"/>
                    <span :class="{hide: logo.url}"><span class="brand">{{ tokens.header.cfd }}</span> {{ tokens.header.platform }}</span>
                </a>
                <div class="tabs" v-if="aspinDesign">
                    <a href="/" :class="{'is-active': modals.login}">Login</a>
                    <a href="/register" :class="{'is-active': modals.register}">Register</a>
                </div>
                <div class="title" v-if="!aspinDesign">
                    <i v-if="newDesign" class="material-icons">edit</i>
                    <span>{{ tokens.registerModal.createNewAccount.toUpperCase() }}</span>
                    <i v-if="!newDesign" class="material-icons">lock</i>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-row input">
                            <label v-if="!aspinDesign">{{ tokens.registerModal.email }}</label>
                            <input :placeholder="`${aspinDesign ? 'Email Address' : ''}`"  class="form-control" v-model="email" @keyup.enter="register" tabindex="1" name="login"/>
                            <div class="icon-box" v-if="aspinDesign">
                                <img :src="`../image/aspin-design/email.png`" alt="">
                            </div>
                        </div>
                        <div v-if="newDesign" class="form-row input">
                            <label v-if="!aspinDesign">{{ tokens.loginModal.fullName }}</label>
                            <input :placeholder="`${aspinDesign ? 'Full Name' : ''}`"  class="form-control" v-model="fullname" @keyup.enter="register" tabindex="2"/>
                            <div class="icon-box" v-if="aspinDesign">
                                <img :src="`../image/aspin-design/name.png`" alt="">
                            </div>
                        </div>
                        <div v-if="!newDesign" class="form-row input">
                            <label>{{ tokens.registerModal.firstName }}</label>
                            <input class="form-control" v-model="firstname" @keyup.enter="register" tabindex="3"/>
                        </div>
                        <div class="form-row input" :class="{'form-icon': newDesign}">
                            <label v-if="!aspinDesign">{{ tokens.registerModal.country }}</label>
                            <i v-if="newDesign" class="material-icons select">arrow_drop_down</i>
                            <div class="search-item ">
                                <button class="search-country" @click.prevent="countrySearchList = !countrySearchList">
                                    {{ country ? country : tokens.registerModal.country  }}
                                </button>
                                <div class="country-list" v-if="countrySearchList">
                                    <input placeholder="Search..." class="search-list newDesign"
                                           v-model="searchedCountry"/>
                                    <div class="country-items">
                                        <p :class="{active: countryItem == country}"
                                           v-for="(countryItem, index) in countries" :key="index"
                                           @click="chooseCountry(countryItem)">
                                            {{ countryItem }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!newDesign" class="form-row">
                            <label>{{ tokens.loginModal.promo }}</label>
                            <div class="show-hide-password">
                                <input class="form-control" ref="password" :type="password1Visible ? 'text' : 'password'" v-model="manager_id"
                                       @keyup.enter="register"
                                       tabindex="7"/>
                                <i class="material-icons"
                                   @touchstart="passwordVisible = !passwordVisible"
                                   @mousedown="passwordVisible = !passwordVisible"
                                >{{passwordVisible ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div v-if="!newDesign" class="form-row input">
                            <label>{{ tokens.registerModal.phone }}</label>
                            <input class="form-control" v-model="phone" @keyup.enter="register" tabindex="2"/>
                        </div>
                        <label v-if="newDesign && !aspinDesign">{{ tokens.registerModal.phone }}</label>
                        <div class="form-row form-icon input" v-if="newDesign">
                            <input class="form-control phone" @keyup="filPhoneNumber" v-model="phone"
                                   @keyup.enter="register" tabindex="4"/>
                            <div class="icon-box" v-if="aspinDesign">
                                <img :src="`../image/aspin-design/phone.png`" alt="">
                            </div>
                            <span v-if="!aspinDesign" class="flag small-flag phone-flag" :class="'flag-'+flag"></span>
                        </div>
                        <div v-if="!newDesign" class="form-row input">
                            <label>{{ tokens.registerModal.lastName }}</label>
                            <input class="form-control" v-model="lastname" @keyup.enter="register" tabindex="4"/>
                        </div>
                        <div class="form-row input">
                            <label v-if="!aspinDesign">{{ tokens.registerModal.password }}</label>
                            <div class="show-hide-password">
                                <input class="form-control" ref="password1" :type="password1Visible ? 'text' : 'password'" v-model="password"
                                       @keyup.enter="register"
                                       :placeholder="`${aspinDesign ? tokens.registerModal.password : ''}`"
                                       tabindex="6"/>
                                <i class="material-icons"
                                   v-if="!aspinDesign"
                                   @touchstart="password1Visible = !password1Visible"
                                   @mousedown="password1Visible = !password1Visible">{{password1Visible ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                                <div class="icon-box" v-if="aspinDesign">
                                    <i class="material-icons" style="color: #fff"
                                       @touchstart="password1Visible = !password1Visible"
                                       @mousedown="password1Visible = !password1Visible">{{password1Visible ? 'visibility' : 'visibility_off' }}</i>
                                </div>
                        </div>
                        <div class="form-row input" v-if="aspinDesign">
                            <label v-if="!aspinDesign">{{ tokens.registerModal.password }}</label>
                            <div class="show-hide-password">
                                <input class="form-control" ref="password2" :type="password2Visible ? 'text' : 'password'" v-model="password2"
                                       @keyup.enter="register"
                                       :placeholder="`${aspinDesign ? tokens.registerModal.repeatPassword : ''}`"
                                       tabindex="6"/>
                                <i class="material-icons"
                                   v-if="!aspinDesign"
                                   @touchstart="password2Visible = !password2Visible"
                                   @mousedown="password2Visible = !password2Visible">{{password2Visible ? 'visibility' : 'visibility_off' }}</i>
                            </div>
                                <div class="icon-box" v-if="aspinDesign">
                                    <i class="material-icons" style="color: #fff"
                                       @touchstart="password2Visible = !password2Visible"
                                       @mousedown="password2Visible = !password2Visible">{{password2Visible ? 'visibility' : 'visibility_off' }}</i>
                                </div>
                        </div>
                        <label v-if="aspinDesign" class="currency">{{ tokens.registerModal.currency }}</label>
                        <div class="form-row input" :class="{'form-icon': newDesign}">
                            <i v-if="newDesign" class="material-icons select">arrow_drop_down</i>
                            <img v-if="newDesign" :src="staticBase+`currencies/${currencyChosen}.png`">
                            <label v-if="!aspinDesign" class="currency">{{ tokens.registerModal.currency }}</label>
                            <select :class="{'form-image': newDesign}" v-model="currencyChosen" class="form-control"
                                    @keyup.enter="register" tabindex="7">
                                <option v-for="(currency, i) in currencies" :key="i" :value="currency">{{
                                        currency
                                    }}
                                </option>
                            </select>
                        </div>
                        <div v-if="existPromo" class="form-row input">
                            <label v-if="!existPromo && !aspinDesign" @click="existPromo = true"
                                   class="promo">{{ tokens.loginModal.havePromo }}</label>
                            <label v-if="existPromo && !aspinDesign">{{ tokens.loginModal.promo }}</label>
                            <div class="show-hide-password">
                                <input v-if="existPromo" class="form-control" ref="promo" type="password"
                                       v-model="manager_id"
                                       @keyup.enter="register"
                                       tabindex="7"
                                       :placeholder="`${tokens.loginModal.promo}`"
                                />
                            </div>
                            <label v-show="existPromo" @click="existPromo = false"
                                   class="promo">{{ tokens.loginModal.hide }}</label>
                        </div>
                    </div>
                </div>
                <div class="form-row" :class="{textCenter: newDesign}">
                    <div class="button green-button" v-on:click="register">
                        {{ tokens.registerModal.createAccount.toUpperCase() }}
                    </div>
                    <div v-if="newDesign" class="form-login">
                        <p>{{ tokens.loginModal.haveAccount }}<a href="/">{{ tokens.loginModal.login }}</a></p>
                    </div>
                    <label v-show="!existPromo || aspinDesign" @click="existPromo = true"
                           class="promo">{{ tokens.loginModal.havePromo }}</label>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {REGISTER} from "@/store/actions.type";
import {NOTIFICATION_ERROR} from '@/store/mutations.type'
import {ASPIN_DESIGN, LOGO, NEW_DESIGN, ROLE, TRADINGVIEW_THEME, WEBSITE} from "@/common/config";
import {countryToPhoneCode, hidePass, showPass, countriesList} from "@/common/helpers";

export default {
    name: "RegisterModal",

    mounted() {
        if (this.newDesign) {
            this.$set(this, 'phone', "+44")
            this.$set(this, 'flag', "gb")
        }
    },
    data() {
        return {
            newDesign: NEW_DESIGN,
            firstname: '',
            lastname: '',
            fullname: '',
            country: '',
            email: '',
            password: '',
            passwordVisible: false,
            password2: '',
            password2Visible: false,
            password1Visible: false,
            manager_id: '',
            existPromo: false,
            role: ROLE,
            currencyChosen: 'USD',
            phone: '',
            flag: '',
            countrySearchList: false,
            searchedCountry: '',
            aspinDesign: ASPIN_DESIGN,
            website: WEBSITE,
            tradingViewTheme: TRADINGVIEW_THEME,
            logo: LOGO,
        }
    },
    methods: {
        showPass,
        hidePass,
        filPhoneNumber() {
            if (this.phone === '' && this.newDesign) {
                this.phone += "+"
            }
        },
        chooseCountry(country) {
            this.country = country
            this.setPhone(country)
            this.countrySearchList = false
        },
        setPhone(country) {
            if (this.newDesign) {
                let selectedCountry = countryToPhoneCode(country)
                if (selectedCountry) {
                    this.$set(this, 'phone', selectedCountry[1])
                    this.$set(this, 'flag', selectedCountry[2].toLowerCase())
                }
            }
        },
        convertFullName(str) {
            let fullname = str.split(' ')
            this.lastname = fullname.pop()
            this.firstname = fullname.join(' ')
        },
        register() {
            if (!this.email) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_EMAIL')
                return
            }

            if (!this.phone) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_PHONE')
                return
            }

            if (this.newDesign) {
                this.convertFullName(this.fullname)
            }

            let manager_id = parseInt(this.manager_id)

            if (isNaN(manager_id) || manager_id <= 0) {
                manager_id = 0
            }

            if (manager_id) {
                this.role = 1
            }

            this.$store.dispatch(REGISTER, {
                firstname: this.firstname,
                lastname: this.lastname,
                country: this.country,
                phone: this.phone,
                email: this.email.trim(),
                currency: this.currencyChosen,
                password: this.password.trim(),
                manager_id,
                role: this.role
            })
        }
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'currencies','staticBase']),
        countries() {
            return Object.values(countriesList()).filter(country => country.toLowerCase().indexOf(this.searchedCountry.toLowerCase()) > -1)
        },
        logoComputed() {
            return this.tradingViewTheme === 'Light' ? this.logo.url.replace(/white/, 'black') : this.logo.url
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.phone-flag {
    top: 50%;
    transform: translateY(-50%);
}

.newDesign .modal {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--color-bg-login);
    border: 1px solid #CECECE;
    border-radius: 10px;
    padding: 30px 40px;
    z-index: 1100;
}

.newDesign .form-row {
    margin-bottom: 10px;
}

.newDesign .form-row:last-child {
    margin-bottom: 0;
}

.newDesign .col {
    margin-bottom: 10px;
}

.newDesign .col:last-child {
    margin-bottom: 0;
}

.newDesign .form-control.tel {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.newDesign .promo {
    margin-top: 10px;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    text-decoration: underline dotted var(--white);
}

.newDesign label {
    margin-bottom: 5px;
    color: var(--white);
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    display: inline-block;
}

.newDesign option {
    color: #000;
}

.newDesign input, .newDesign select {
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
}

.footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    align-items: center;
}

.form-login p {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.6);
}

.newDesign ::placeholder {
    color: transparent;
}

.form-row.textCenter {
    align-items: center;
}

.form-login p a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--white);
    margin-left: 5px;
    cursor: pointer;
}

.newDesign .row {
    flex-direction: column;
    width: 100%;
}

.newDesign .row .col {
    margin-right: 0;
}

.newDesign .button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
    background: var(--color-bg-login-btn);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.modal {
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--background-dark);
    padding: 25px;
    border: 1px solid var(--border);
    z-index: 1100;
}

.newDesign .title {
    justify-content: flex-start;
    gap: 5px;
}

.search-country {
    width: 100%;
    height: 31px;
    text-align: left;
    width: 100%;
    background: rgba(180, 180, 180, 0.3);
    border-radius: 5px;
    color: var(--white);
    border: 0;
}

.search-item {
    position: relative;
    width: 100%;
}

.country-list {
    position: absolute;
    top: 35px;
    left: 0;
    width: 100%;
    background: rgba(124, 124, 124, 1);
    border-radius: 5px;
    color: var(--white);
    padding: 8px 0;
    z-index: 10;
    display: grid;
    grid-template-rows: 1fr max-content;
    gap: 10px;
}

.country-list p {
    color: var(--white);
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 2px 12px;
    background-color: transparent;
    font-size: 15px;
}

.country-list input {
    padding: 5px;
    outline: none;
    border: 0;
    border-radius: 5px;
    width: 95%;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.45);
}

.country-list input::placeholder {
    color: var(--white);
}

.country-list p.active,
.country-list p:hover {
    background-color: var(--brand);
}

.country-items {
    max-height: 200px;
    overflow-y: scroll;
    padding-top: 5px;
    width: 100%;
    border-radius: 5px;
    color: var(--white);
}

.title {
    color: var(--white);
    font-weight: 600;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title i {
    font-size: 20px;
}

.col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.col:first-child {
    margin-right: 30px;
}

label {
    margin-bottom: 5px;
}

input, select {
    width: 100%;
}

.row {
    align-items: stretch;
    flex-wrap: nowrap;
}

.button {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-weight: 600;
    cursor: pointer;
}

/* aspin login/register styles */
.aspin-modal-design.modal-wrapper {
    justify-content: flex-end;
}

.aspin-modal-design .modal {
    width: 660px;
    height: 100%;
    justify-content: center;
    padding: 20px 140px;
    position: relative;
    background: transparent;
    border: none;
    display: inline-block;
    overflow: auto;
}

.aspin-modal-design .search-country,
.aspin-modal-design .form-control {
    padding: 0 10px;
}

.aspin-modal-design .modal::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1E1F25;
    opacity: 0.75;
    backdrop-filter: blur(32px);
    z-index: -1;
}

.aspin-logo {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 0 35px;
}

.aspin-modal-design .show-hide-password {
    background: transparent;
}

.aspin-modal-design .search-country,
.aspin-modal-design .form-control{
   background-color: transparent;
}

.aspin-modal-design .col {
    margin: 0;
}

.aspin-modal-design .form-icon.form-row,
.aspin-modal-design .form-row {
    position: relative;
    margin-bottom: 20px;
}

.aspin-modal-design .form-icon .select {
    font-size: 30px;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
}

.aspin-modal-design .show-hide-password i {
    right: 20px;
}

.aspin-modal-design input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #FFFFFF;
}

.aspin-modal-design .button {
    margin: 55px 0 5px 0;
    padding: 16px 0;
    background: linear-gradient(265.56deg, #30E0A1 -0.27%, #1A82FF -0.26%, #1E02C7 98.59%);
    border-radius: 100px;
}

.aspin-modal-design .currency {
    margin-left: 35px;
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
}

.aspin-modal-design .form-icon img {
    width: 32px;
    height: 25px;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    z-index: 2;
}

.aspin-modal-design .icon-box img {
    width: auto;
    height: auto;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
}

.aspin-modal-design .form-image {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    padding: 0 48px;
    position: relative;
}

.aspin-modal-design option {
    color: #fff;
}

.tabs {
    margin-bottom: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}

.tabs a {
    font-weight: 600;
    font-size: 24px;
    color: var(--white);
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.tabs a.is-active {
    color: #1A82FF;
    font-weight: 600;
}

.tabs a:first-child{
    padding-right: 25px;
}

.tabs a:last-child {
    padding-left: 25px;
}

.tabs a:first-child::after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.aspin-modal-design .country-list {
    top: 50px;
}

.aspin-modal-design .country-list,
.aspin-modal-design .form-row.input,
.aspin-modal-design .form-image {
    background: #5D6588;
}

.aspin-modal-design .form-row.input {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 30px;
    place-items: center;
    height: 60px;
    border: 1px solid #34384C;
    border-radius: 100px;
    padding: 0 21px;
    font-weight: 400;
    font-size: 16px;
}

.aspin-modal-design .icon-box {
    position: relative;
    padding-left: 10px;
}

.aspin-modal-design .icon-box::after{
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: var(--white);
}

.icon-box img {
    cursor: pointer;
}
.mobile .aspin-modal-design .modal {
    padding: 20px;
}
</style>
