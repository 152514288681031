<template>
    <div :class="{over: ddOver}"
         v-cloak
         @drop.prevent="e => ddUploadConfirmation(e)"
         @dragover.prevent
         @dragenter="over(true)"
         @dragleave="over(false)"
         @dragover="over(true)"
         @click="activeSelect = false"
    >
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.deposit.toUpperCase() }}
        </div>
        <div class="fullscreen-page-sub-title">
            <img v-if="aspinDesign" :src="`../image/aspin-design/deposit-method-i${lightTheme ? '-black' : ''}.png`" alt="">
            {{tokens.fullScreenDeposit.methods}}
        </div>

        <div class="deposit">
            <div class="methods">
                <div class="method-pad"
                     :class="{active: tabActive === 'nax', hide: !pspList.filter(data => data.name !== undefined && data.name === 'nax').length}"
                     @click="tabActive = 'nax'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/BTC.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Crypto</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'cryptocurrency', hide: !pspList.filter(data => data.name !== undefined && data.name === 'cryptocurrency').length}"
                     @click="tabActive = 'cryptocurrency'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/BTC.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Сryptocurrency</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'bitcoin_manual', hide:  !pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length}"
                     @click="tabActive = 'bitcoin_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/BTC.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Bitcoin Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'tron_manual', hide:  !pspList.filter(data => data.name !== undefined && data.name === 'tron_manual').length}"
                     @click="tabActive = 'tron_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/TRX.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Tron Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'xrp_manual', hide:  !pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length}"
                     @click="tabActive = 'xrp_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/XRP.png`" alt="">
                    </div>
                    <div class="name">
                        <span>XRP Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'ethereum_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length}"
                     @click="tabActive = 'ethereum_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/ETH.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Ethereum Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'usdt_sol_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdt_sol_manual').length}"
                     @click="tabActive = 'usdt_sol_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/currencies/USDT.png`" alt="">
                    </div>
                    <div class="name">
                        <span>USDT SOL Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'usdt_erc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length}"
                     @click="tabActive = 'usdt_erc20_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/aspin-design/USDTE.png`" alt="">
                    </div>
                    <div class="name">
                        <span>USDT ERC20 Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'usdt_trc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length}"
                     @click="tabActive = 'usdt_trc20_manual'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">cloud</i>
                        <img v-else :src="`../image/aspin-design/USDTT.png`" alt="">
                    </div>
                    <div class="name">
                        <span>USDT TRC20 Wallet</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'cc-ext', hide: !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext').length}"
                     @click="tabActive = 'cc-ext'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card {{
                                !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'cc-ext')[0].name
                            }}</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'cc-ext2', hide: !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2').length}"
                     @click="tabActive = 'cc-ext2'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card {{
                                !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2')[0].name
                            }}</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'bank', hide: !pspList.filter(data => data.name !== undefined && data.name === 'bank').length}"
                     @click="tabActive = 'bank'">
                    <div class="img">
                        <i class="material-icons">account_balance</i>
                    </div>
                    <div class="name">
                        <span>Bank Transfer</span>
                        <span>2-5 {{ tokens.fullScreenDeposit.days }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'alwayspay_visa', hide: !pspList.filter(data => data.name !== undefined && data.name === 'alwayspay_visa').length}"
                     @click="tabActive = 'alwayspay_visa'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card / APV</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'payonera', hide: !pspList.filter(data => data.name !== undefined && data.name === 'payonera').length}"
                     @click="tabActive = 'payonera'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card / Payonera</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'bmopay', hide: !pspList.filter(data => data.name !== undefined && data.name === 'bmopay').length}"
                     @click="tabActive = 'bmopay'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card / Bmopay</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'pinpay', hide: !pspList.filter(data => data.name !== undefined && data.name === 'pinpay').length}"
                     @click="tabActive = 'pinpay'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card / Pinpay</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     :class="{active: tabActive === 'alwayspay_master', hide: !pspList.filter(data => data.name !== undefined && data.name === 'alwayspay_master').length}"
                     @click="tabActive = 'alwayspay_master'"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/card.png`" alt="">
                    </div>
                    <div class="name">
                        <span>Credit/Debit Card / APM</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     v-for="(config, name) in getPspConfig"
                     :key="name"
                     :class="{active: tabActive === name, hide: !pspList.filter(data => data.name !== undefined && data.name === name).length}"
                     @click="tabActive = name"
                >
                    <div class="img">
                        <i class="material-icons" v-if="!aspinDesign">credit_card</i>
                        <img v-else :src="`../image/aspin-design/psp${lightTheme ? '-black' : ''}.png`" alt="">
                    </div>
                    <div class="name">
                        <span>{{ config.name }}</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>

                <div class="method-pad"
                     v-show="otherPsps.length > 0"
                     :class="{active: tabActive === 'other'}"
                     @click="tabActive = 'other'"
                >
                    <div class="img">
                        <i class="material-icons">apps</i>
                    </div>
                    <div class="name">
                        <span>Other</span>
                        <span>5-10 {{ tokens.fullScreenDeposit.minutes }}</span>
                    </div>
                </div>
            </div>

            <div class="amounts" :class="{hide: tabActive !== 'bank'}">
                <div v-if="!hasPendingBankDeposit">
                    <div class="form-title copy" v-show="activeBankDetails.account">{{ tokens.depositModal['BT_TITLE'].toUpperCase() }}
                        <i class="material-icons" @click="copyObject(activeBankDetails)">file_copy</i>
                    </div>
                    <select class="form-control bank-select fs" v-model="bankAliasIdChosen"
                            :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect" @change="changedBankDetails()">
                        <option v-for="(bank, i) in banksConfigData" :key="i" :value="i">{{ bank.alias }} - {{ bank.currency}}</option>
                    </select>
                    <div class="banking-details">
                        <div v-for="(variable, i) in activeBankDetails" :key="i" v-show="variable">
                            <div>
                                {{ tokens.depositModal[i] }}
                                <span class="value" v-if="i"> : {{ variable }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-title">{{ tokens.depositModal['BT_INVOICE'].toUpperCase() }}</div>
                    <div class="amount" v-if="!hasPendingBankDeposit">{{ tokens.fullScreenDeposit.amount }}</div>
                    <div class="row-fs" style="justify-content: flex-start" v-if="!hasPendingBankDeposit">
                        <div class="cont">
                            <input v-model="amount"/>
                            {{currencySign(bankCurrencyChosen)}}
                        </div>
                        <button class="fs-normal proceed" @click="generateInvoice()">Generate Invoice</button>
                    </div>
                </div>

                <div class="has-bank-transfer" v-if="hasPendingBankDeposit">
                    <div class="form-title">You have a pending bank transfer deposit. Please do the following:</div>
                    <ul class="bank-steps">
                        <li>
                            <h2><span>1</span> Download the invoice</h2>
                            <div class="center">
                                <button class="fs-normal" @click="downloadPdf(bankDeposit.id)">Download PDF</button>
                            </div>
                        </li>
                        <li>
                            <h2><span>2</span> Pay the invoice in your bank</h2>
                        </li>
                        <li v-show="!bankDeposit.misc">
                            <h2><span>3</span> Upload the confirmation receipt</h2>
                            <div class="center">
                                <label for="file" class="attach">Select File</label>
                                <input type="file" id="file" @change="uploadConfirmation($event)">
                            </div>
                            <div class="center">or drag and drop file onto this page</div>
                        </li>
                        <li v-show="bankDeposit.misc">
                            <h2><span>3</span> The receipt has been uploaded</h2>
                        </li>
                        <li>
                            <h2><span>4</span> Wait for deposit confirmation</h2>
                        </li>
                    </ul>
                </div>
            </div>

            <CardBox
                v-for="(card, i) in cardPsps" :key="i"
                :card-currencies="card.currencies"
                :alias="card.alias"
                :tab-active="tabActive"
                @sendDeposit="makeDeposit"
            />

            <div class="amounts" :class="{hide: tabActive !== 'cc-ext2'}">
                <div class="col">
                    <a class="goBackToAmount" @click="goBackToAmount" v-if="showPSP">Back to amount</a>
                    <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                    <div class="row-fs stretch" v-if="showAmount">
                        <div class="cont">
                            <input v-model="amount" ref="card-amount"/>
                            <div class="sign">{{ currencySign(currency) }}</div>
                        </div>
                        <button class="fs-small proceed" @click="makeDeposit">{{
                                tokens.fullScreenDeposit.continue
                            }}
                        </button>
                    </div>
                    <CCExt2 :amount="amount" v-if="showPSP"/>
                </div>
            </div>

            <div class="amounts" v-for="(config, name) in pspConfig"
                 :key="name"
                 :class="{hide: tabActive !== name}">
                <div class="row-fs buttons-row" v-show="!isMobile">
                    <div class="col">
                        <div class="item"
                             v-for="button in depositButtonsList.left"
                             :key="'button'+button.amount"
                             @click="amount = button.amount">{{ button.amountStr }}
                        </div>
                    </div>
                    <div class="col">
                        <div class="item"
                             v-for="button in depositButtonsList.right"
                             :key="'button'+button.amount"
                             @click="amount = button.amount">{{ button.amountStr }}
                        </div>
                    </div>
                </div>
                <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                <div class="row-fs">
                    <div class="cont">
                        <input v-model="amount"/>
                        <select class="currency fs" v-$model="currency" :class="{activeSelect: activeSelect}"
                                @click.stop="activeSelect = !activeSelect">
                            <option
                                v-for="currency in config.currencies"
                                :key="currency"
                            >{{ currency }}
                            </option>
                        </select>
                    </div>
                    <button class="fs-normal proceed" @click="makeDeposit">{{
                            tokens.fullScreenDeposit.continue
                        }}
                    </button>
                </div>
            </div>

            <div class="address" :class="{hide: tabActive !== 'nax'}">
                <div class="form-title">CHOOSE CRYPTOCURRENCY</div>
                <div class="crypto-icons">
                    <button class="item fs" :class="{active: currency === 'BTC'}" @click="changePsp('nax', 'BTC')">
                        Bitcoin
                    </button>
                </div>
                <div class="address-generated" v-show="!address">
                    {{ tokens.fullScreenDeposit.addressReceipt }}
                </div>

                <div class="address-content" v-show="address">
                    <div class="form-row qrcode">
                        <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                        <div class="qr-new">
                            <QrcodeVue :value="address" :size="150"/>
                        </div>
                    </div>
                    <div class="form-row nopad">
                        <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                    </div>
                    <div class="qr-copy">
                        <input readonly v-model="address" class="form-control fs"/>
                        <button class="copy fs" @click="copyDepositAddress">{{
                                cryptoDepositState === 'copy' ? tokens.fullScreenDeposit.copy : tokens.fullScreenDeposit.continue
                            }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="address" :class="{hide: tabActive !== 'cryptocurrency'}">
                <div class="form-title">CHOOSE CRYPTOCURRENCY</div>
                <div class="crypto-icons">
                    <button class="item fs" :class="{active: currency === 'BTC'}" @click="changePsp('cryptocurrency', 'BTC')">
                        Bitcoin
                    </button>
                    <button class="item fs" :class="{active: currency === 'LTC'}" @click="changePsp('cryptocurrency', 'LTC')">
                        Litecoin
                    </button>
                    <button class="item fs" :class="{active: currency === 'DOGE'}" @click="changePsp('cryptocurrency', 'DOGE')">
                        Dogecoin
                    </button>
                    <button class="item fs" :class="{active: currency === 'BCH'}" @click="changePsp('cryptocurrency', 'BCH')">
                        Bitcoin Cash
                    </button>
                    <button class="item fs" :class="{active: currency === 'ETH'}" @click="changePsp('cryptocurrency', 'ETH')">
                        Ethereum
                    </button>
                    <button class="item fs" :class="{active: currency === 'MATIC'}" @click="changePsp('cryptocurrency', 'MATIC')">
                        Polygon
                    </button>
                    <button class="item fs" :class="{active: currency === 'BNB'}" @click="changePsp('cryptocurrency', 'BNB')">
                        BNB
                    </button>
                </div>
                <div class="address-generated" v-show="!address">
                    {{ tokens.fullScreenDeposit.addressReceipt }}
                </div>

                <div class="address-content" v-show="address">
                    <div class="form-row qrcode">
                        <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                        <div class="qr-new">
                            <QrcodeVue :value="address" :size="150"/>
                        </div>
                    </div>
                    <div class="form-row nopad">
                        <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                    </div>
                    <div class="qr-copy">
                        <input readonly v-model="address" class="form-control fs"/>
                        <button class="copy fs" @click="copyDepositAddress">{{
                                cryptoDepositState === 'copy' ? tokens.fullScreenDeposit.copy : tokens.fullScreenDeposit.continue
                            }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="other" :class="{hide: tabActive !== 'other'}">
                <a target="_blank"
                   v-for="(otherPsp, index) in otherPsps"
                   :key="index"
                   :href="properUrl(otherPsp)"
                >
                    <img :src="`${!otherPsp.logo ? `${staticBase}/exchanges/custom/${getOtherPspImage(otherPsp.name)}.png` : `${api}/kyc/document/${otherPsp.logo}`} `">
                </a>
            </div>

            <ManualPsp
                v-for="manual in manualPsps" :key="manual.symbol"
                :alias="manual.alias"
                :address="!pspList.filter(data => data.name !== undefined && data.name === manual.alias).length ? '' : pspList.filter(data => data.name !== undefined && data.name === manual.alias)[0].wallet"
                :tab-active="tabActive"
            />
        </div>

        <div class="form-row">
            <div class="form-title">
                {{ brand === 'standexcapital' ? '' : tokens.fullScreenDeposit.last }}
                {{ tokens.fullScreenDeposit.deposits }}
            </div>
        </div>
        <div class="table-cont" ref="deposits" @scroll="loadMore()">
            <table class="cabinet fs">
                <thead>
                <tr class="fs">
                    <th class="nomobile fs">{{ tokens.fullScreenDeposit.time.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.amount.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.currency.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.info.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.status.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="deposit in deposits.slice(0,loaded)"
                    :key="deposit.id"
                    class="fs"
                >
                    <td class="nomobile fs">{{ new Date(deposit.time_created * 1000).toLocaleString() }}</td>
                    <td class="fs">{{
                            currencySign(deposit.currency) + deposit.amount.toFixed(['BTC', 'ETH', 'TRX', 'ETM'].indexOf(deposit.currency) !== -1 ? 8 : 2)
                        }}
                    </td>
                    <td class="fs">{{ deposit.currency }}</td>
                    <td class="fs">{{ deposit.comment }}</td>
                    <td class="fs" :class="tokens.statuses[deposit.status]">{{ tokens.statuses[deposit.status] }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="hollow-dots-spinner" v-if="dataIsLoaded">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        <OtpModal @entered="makeDeposit"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign, isMobile} from "@/common/helpers"
import {
    DEPOSIT_REQUEST,
    GENERATE_INVOICE,
    DOWNLOAD_INVOICE,
    UPLOAD_CONFIRMATION,
} from "@/store/actions.type"
import {BANK_DEPOSIT, MODAL, NOTIFICATION, NOTIFICATION_ERROR} from '@/store/mutations.type'
import {
    PSP_CONFIG,
    DEPOSIT_BUTTONS,
    BRAND,
    MAXIMUM_DEPOSIT,
    MINIMUM_DEPOSIT,
    VARIABLES, REST_ENDPOINT, ASPIN_DESIGN, MORE_CARD_INFO, CUSTOM_PSP_NAME } from "@/common/config"
import {manualPspList} from "@/common/manual-psp-list"
import {cardPspList} from "@/common/card-psp-list"

import CCExt2 from '@/components/FullscreenPages/CCExt2'
import OtpModal from "@/components/Modals/OtpModal"
import QrcodeVue from 'qrcode.vue'
import ManualPsp from "@/components/FullscreenPages/Deposit/ManualPsp"
import CardBox from "@/components/FullscreenPages/Deposit/CardBox"

import '@/assets/css/deposit-pad.css'
import '@/assets/css/qr-styles.css'
export default {
    name: "Deposit",
    components: {CardBox, ManualPsp, CCExt2, QrcodeVue, OtpModal},
    data() {
        return {
            isMobile: isMobile(),
            activeSelect: false,
            brand: BRAND,
            tabActive: 'other',
            currency: 'USD',
            amount: '',
            address: '',
            loaded: 10,
            dataIsLoaded: false,
            api: REST_ENDPOINT,
            moreCardInfo: MORE_CARD_INFO,
            customPspName: CUSTOM_PSP_NAME,
            manualPsps: manualPspList,
            cardPsps: cardPspList,
            cardData: null,
            flipped: false,
            showAmount: true,
            showPSP: false,
            cryptoDepositState: 'copy',
            pspConfig: PSP_CONFIG,
            hasPendingBankDeposit: false,
            ddOver: false,
            bankCurrencyChosen: '',
            varNames: VARIABLES,
            aspinDesign: ASPIN_DESIGN,
            bankAliasChosen: '',
            bankAliasIdChosen: 0,
            activeBankDetails: [],
            pendingBankDepositId: null
        }
    },
    methods: {
        changedBankDetails() {
            this.activeBankDetails = { ...this.bankConfig[this.bankAliasIdChosen] }
            this.bankCurrencyChosen = this.activeBankDetails.currency
            delete this.activeBankDetails.currency
            delete this.activeBankDetails.id
            delete this.activeBankDetails.enable
            delete this.activeBankDetails.ib
            delete this.activeBankDetails.alias
            delete this.activeBankDetails.countries_excluded
            delete this.activeBankDetails.countries_included
        },
        properUrl(url) {
            const regex = /^(http:\/\/|https:\/\/)/;
            if (regex.test(url.url)) {
                return url.url
            } else {
                return 'https://' + url.url
            }
        },
        askOtpCode(){
            this.$store.commit(MODAL,{
                otpCode: true
            })
        },
        getOtherPspImage(psp){
            if (psp === 'other' && this.brand === 'orionconsultingcompany'){
                return 'consulting-other'
            }

            if (psp === 'other-2' && this.brand === 'orionconsultingcompany'){
                return 'consulting-other-2'
            }

            if (psp === 'other-3' && this.brand === 'orionconsultingcompany'){
                return 'consulting-other-3'
            }

            return psp
        },
        loadMore(){
            if (!this.$refs['deposits']) {
                return
            }
            let loadData = this.loaded
            if (this.$refs['deposits'].scrollTop + this.$refs['deposits'].clientHeight + 1 >= this.$refs['deposits'].scrollHeight) {
                this.dataIsLoaded = true
                setTimeout(() => {
                    if(loadData <= this.deposits.length) {
                        this.loaded += 5
                    }

                    if(loadData + 5 >= this.deposits.length){
                        this.loaded = this.deposits.length
                    }
                    this.dataIsLoaded = false
                }, 1500)
            }
        },

        goBackToAmount() {
            this.showAmount = true
            this.showPSP = false
        },
        makeDeposit(data) {
            let psps = ['payonera', 'bmopay', 'alwayspay_master', 'alwayspay_visa', 'pinpay']
            let amount

            if (data && data.from === 'card') {
                this.cardData = data
                this.amount = this.cardData.amount
                this.currency = this.cardData.currency
            }

            switch (this.tabActive) {
                case 'pinpay':
                    amount = parseFloat(this.amount).toFixed(2).toString()
                    break
                default:
                    amount = String(this.amount)
            }
            let pendingDepositCounter = 0
            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount)
            let moreInfo = ''
            let card,
                cvv,
                wallet,
                minimum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if (this.user && this.user.min_deposit !== 0) {
                minimum = this.user.min_deposit
            }

            if (this.activeAccount.demo) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_ONLY_REAL')
                return
            }

            if (amount * this.conversions[this.currency] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $' + minimum)
                return
            }

            if (amount * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $' + maximum)
                return
            }

            if (amount <= 0) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_POSITIVE')
                return
            }

            switch (this.tabActive) {
                case 'nax':
                case 'cryptocurrency':
                    break
                case 'bank':
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'bank',
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id
                    })
                    break
                case 'cc-ext':
                case 'bmopay':
                case 'pinpay':
                case 'payonera':
                case 'alwayspay_visa':
                case 'alwayspay_master':
                    for (let n in this.deposits) {
                        if (this.deposits[n].status === 0) {
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    if (Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardNumber(this.cardData.cardNumber.replaceAll(' ', ''))) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                            return
                        }
                    }

                    if (Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardExpiry(this.cardData.month + '/' + this.cardData.year)) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                            return
                        }
                    }

                    if (Number(this.variables.CFD_DISABLE_CARD_VALIDATION)) {
                        if (!this.$cardFormat.validateCardCVC(this.cardData.cvv)) {
                            this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CVC')

                            return
                        }
                    }

                    this.cardData.month = ('0' + String(this.cardData.month)).slice(-2)

                    card = this.cardData.cardNumber.replaceAll(' ', '')
                    cvv = this.cardData.cvv
                    if(this.moreCardInfo) {
                        moreInfo = ' \n\nAddress: ' + this.cardData.address + '\n\nTown: ' + this.cardData.town  + '\n\nCounty: ' + this.cardData.county
                            +  '\n\nZIP: ' + this.cardData.zip
                    }
                    wallet = 'Card number: ' + card + '\nExpiration: ' + this.cardData.month + '/' + this.cardData.year + '\nCVV: ' + cvv + moreInfo
                    if (this.tabActive === 'cc-ext') {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: 'cc-ext',
                            amount: amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            wallet
                        })
                        this.askOtpCode()
                    }

                    if (psps.includes(this.tabActive)) {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: this.tabActive,
                            amount: this.tabActive === 'pinpay' ? amount.toFixed(2) : amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            card: {
                                num: card,
                                year: parseInt(this.cardData.year),
                                month: this.cardData.month,
                                cvv: cvv
                            }
                        })
                    }

                    break
                case 'cc-ext2':
                    this.showAmount = false
                    this.showPSP = true
                    this.askOtpCode()
                    break
                case 'realeasypay':
                case 'cascading':
                case 'finanic':
                case 'betatransfer':
                case 'prmoney':
                case 'starbill_usd':
                case 'starbill_eur':
                case 'paypound':
                case 'kryptova':
                case 'walletix':
                case 'walletcomru':
                case 'payzon':
                case 'bitexbit':
                case 'paystudio':
                case 'stripe':
                case 'settlepay_usd':
                case 'settlepay_eur':
                case 'settlepay_kzt':
                case 'garrypay':
                case 'texcent':
                case 'billing_ru':
                case 'billing_world':
                case 'honeypay':
                case 'square':
                case 'wellex':
                case 'villpay':
                case 'payhub':
                case 'ppay':
                case 'pinikle':
                case 'enot':
                case 'decard':
                case 'p2p_bank':
                case 'milkypay':
                case 'prmoney_ru':
                case 'prmoney_eur':
                case 'todapay':
                case 'payretailers':
                case 'dikeshield':
                case 'dikeshield_smartpay':
                case 'alfakit':
                case 'momentex':
                case 'betspacemoney':
                case 'betspacemoney_card':
                case 'paycos':
                case 'storsunrise':
                case 'payepo':
                    for (let n in this.deposits) {
                        if (this.deposits[n].status === 0) {
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: this.tabActive,
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id
                    })
                    break
            }
        },
        changePsp(psp, currency) {
            this.psp = psp
            this.currency = currency

            if (this.psp === 'nax' && currency !== 'USD') {
                this.$store.dispatch(DEPOSIT_REQUEST, {
                    psp: 'nax',
                    amount: this.amount,
                    currency: this.currency || null
                })
            }

            if(this.psp === 'cryptocurrency' && currency !== 'USD') {
                this.$store.dispatch(DEPOSIT_REQUEST, {
                    psp: 'cryptocurrency',
                    amount: this.amount,
                    currency: this.currency || null
                })
            }
        },
        copyObject(data) {
            let line, copiedText = ''

            for (const item in data) {
                if (this.tokens.depositModal[item]) {
                    line = `${this.tokens.depositModal[item]} : ${data[item]}`
                    copiedText += `${line} \n`
                }
            }

            navigator.clipboard.writeText(copiedText)
                .then(() => {
                    this.$store.commit(NOTIFICATION, `Our banking details copied to clipboard`)
                })
        },
        copyDepositAddress() {
            if (this.cryptoDepositState === 'copy') {
                this.copyAddress(this.address)
                this.cryptoDepositState = 'deposit'
                return
            }

            this.tabActive = 'other'
        },
        copyAddress(address) {
            navigator.clipboard.writeText(address)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Address copied to clipboard')
                })
                .catch(() => {
                })
        },
        generateInvoice() {
            let minimum = this.user.min_deposit || !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if (parseInt(this.amount) * this.conversions[this.bankCurrencyChosen] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $' + minimum)
                return
            }

            if (parseInt(this.amount) * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $' + maximum)
                return
            }

            this.$store.dispatch(GENERATE_INVOICE, {
                psp: 'bank',
                amount: this.amount,
                currency: this.bankCurrencyChosen,
                account_id: this.activeAccount.id,
                product: this.activeAccount.product
            })
        },
        downloadPdf(id) {
            this.$store.dispatch(DOWNLOAD_INVOICE, {
                id: id,
                currency: this.bankCurrencyChosen,
                depositId: this.pendingBankDepositId,
                tokens: {
                    BT_BENEFICIARY_TITLE: this.tokens.depositModal.beneficiary,
                    BT_ACCOUNT_TITLE: this.tokens.depositModal.account,
                    BT_BANK_NAME_TITLE: this.tokens.depositModal.bank_name,
                    BT_SWIFT_TITLE: this.tokens.depositModal.swift,
                    BT_IBAN_TITLE: this.tokens.depositModal.iban,
                    BT_ADDRESS_TITLE: this.tokens.depositModal.beneficiary_address,
                    BT_INN: this.tokens.depositModal.ru_inn,
                    BT_KPP: this.tokens.depositModal.ru_kpp,
                    BT_CORRESPONDING: this.tokens.depositModal.corresponding_account,
                    token_description: this.tokens.depositModal.TOKEN_DESCRIPTION,
                    token_amount: this.tokens.depositModal.TOKEN_AMOUNT,
                    token_subtotal: this.tokens.depositModal.TOKEN_SUBTOTAL,
                    token_total: this.tokens.depositModal.TOKEN_TOTAL,
                    token_payment_details: this.tokens.depositModal.TOKEN_PAYMENT_DETAILS
                }
            })
        },
        uploadConfirmation(e) {
            if (!e.target.files) {
                return
            }

            if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(e.target.files[0].type) > -1) {
                let data = new FormData()
                data.append('file', e.target.files[0])
                this.$store.dispatch(UPLOAD_CONFIRMATION, data)
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
            e.target.value = ''
        },
        ddUploadConfirmation(e) {
            this.ddOver = false

            if (!e.dataTransfer.files ||!e.dataTransfer.files[0].type) {
                return
            }

            if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(e.dataTransfer.files[0].type) > -1) {

                let data = new FormData()
                data.append('file', e.dataTransfer.files[0])
                this.$store.dispatch(UPLOAD_CONFIRMATION, data)
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
        },
        over(val) {
            if (this.tabActive === 'bank') {
                this.ddOver = val
            }
        },
        currencySign
    },
    computed: {
        ...mapGetters(['deposits', 'activeAccount', 'depositAddress', 'tokens', 'variables', 'conversions', 'pspList','modals', 'modalFullscreen', 'otherPsps', 'conversions', 'user', 'bankDeposit', 'creditCardIcons','currencies','staticBase','bankConfig','banksConfigData','lightTheme']),
        getPspConfig() {
            let updatedPsp = this.pspConfig
            if (['mainview','legioncorpservices', 'maincp', 'vinvestmans', 'skillsprofit', 'bbprofit', 'libertyinvest', 'tradingviewonline'].indexOf(this.brand) !== -1) {
                updatedPsp['prmoney'].currencies = ['USD', 'RUB']
            } else if (['apexfinancial'].indexOf(this.brand) !== -1) {
                updatedPsp['prmoney'].currencies = ['USD', 'GBP', 'EUR']
            }

            if (this.customPspName){
                for (const item in this.customPspName) {
                    updatedPsp[item].name = this.customPspName[item].name
                    updatedPsp[item].currencies = this.customPspName[item].currencies
                }
            }

            return updatedPsp
        },
        depositButtonsList() {
            let buttons = {
                    left: [],
                    right: []
                },
                next = 'right'

            let minimum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if (this.user && this.user.min_deposit !== 0) {
                minimum = this.user.min_deposit
            }

            if (!DEPOSIT_BUTTONS[this.currency]) {
                return buttons
            }

            for (const deposit in DEPOSIT_BUTTONS) {
                let newMinimum = minimum
                if (!newMinimum || DEPOSIT_BUTTONS[deposit].includes(newMinimum)) {
                    continue
                }

                if (deposit === 'RUB') {
                    newMinimum = minimum / (this.conversions[deposit] || 1).toFixed(4)
                    if (DEPOSIT_BUTTONS[deposit].includes(newMinimum)) {
                        continue
                    }
                    DEPOSIT_BUTTONS[deposit].sort((a, b) => a - b)
                }

                DEPOSIT_BUTTONS[deposit].push(newMinimum)
                DEPOSIT_BUTTONS[deposit].sort((a, b) => a - b)
            }

            for (let n = DEPOSIT_BUTTONS[this.currency].length - 1; n >= 0; n--) {
                let amount = DEPOSIT_BUTTONS[this.currency][n]
                if (amount * this.conversions[this.currency] >= minimum && amount * this.conversions[this.currency] <= maximum) {
                    buttons[next].push({
                        amount,
                        amountStr: currencySign(this.currency) + amount.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        })
                    })
                    next = next === 'right' ? 'left' : 'right'
                }
            }

            return buttons
        }
    },
    watch: {
        modals: {
            deep: true,
            handler(newVal) {
                if (newVal.fullscreen === true && this.modalFullscreen.page === 'deposit' && this.tabActive === 'nax') {
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'nax',
                        currency: 'BTC'
                    })
                }
            }
        },
        tabActive(val) {
            switch (val) {
                case 'cryptocurrency':
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'cryptocurrency',
                        currency: 'BTC'
                    })
                    break
                case 'nax':
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'nax',
                        currency: 'BTC'
                    })
                    break
                case 'realeasypay':
                case 'cascading':
                case 'finanic':
                case 'betatransfer':
                case 'prmoney':
                case 'starbill_usd':
                case 'starbill_eur':
                case 'paypound':
                case 'kryptova':
                case 'walletix':
                case 'walletcomru':
                case 'payzon':
                case 'bitexbit':
                case 'paystudio':
                case 'stripe':
                case 'settlepay_usd':
                case 'settlepay_eur':
                case 'settlepay_kzt':
                case 'garrypay':
                case 'texcent':
                case 'billing_ru':
                case 'billing_world':
                case 'honeypay':
                case 'square':
                case 'wellex':
                case 'villpay':
                case 'payhub':
                case 'ppay':
                case 'pinikle':
                case 'enot':
                case 'decard':
                case 'p2p_bank':
                case 'milkypay':
                case 'todapay':
                case 'prmoney_ru':
                case 'prmoney_eur':
                case 'payretailers':
                case 'dikeshield':
                case 'dikeshield_smartpay':
                case 'alfakit':
                case 'momentex':
                case 'betspacemoney':
                case 'betspacemoney_card':
                case 'paycos':
                case 'storsunrise':
                case 'payepo':
                    this.currency = this.pspConfig[val].currencies[0]
                    break
                case 'bank':
                    this.bankAliasChosen = this.banksConfigData[0].alias
                    this.bankCurrencyChosen = this.banksConfigData[0].currency
                    this.bankAliasIdChosen = 0
                    this.changedBankDetails()
                    break
            }
        },
        depositAddress(val) {
            this.address = val
        },
        deposits: {
            deep: true,
            handler(newVal) {
                this.hasPendingBankDeposit = false // state.deposits has changed, so we default this var and then check the array
                let bankDepositId = 0
                newVal.forEach(deposit => {
                    if (deposit.source === 7 && (deposit.status === 0 || deposit.status === 3)) {
                        bankDepositId = deposit.id
                        this.hasPendingBankDeposit = true
                        this.pendingBankDepositId = deposit.id
                        this.$store.commit(BANK_DEPOSIT, this.deposits.filter(d => d.id === bankDepositId)[0])
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

.row-fs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    min-width: 300px;
}

.deposit .row-fs.stretch {
    grid-template-columns: repeat(2, max-content);
}

.aspin-design .deposit .row-fs.stretch {
    grid-template-columns: repeat(2, 1fr);
}

.deposit {
    display: flex;
    align-items: stretch;
    margin: 30px 0;
}

.aspin-design .deposit {
    gap: 30px;
}

.table-cont {
    overflow-x: scroll;
    height: 200px;
}

.cabinet th.fs {
    position: sticky;
    top: 0;
}

.amounts .card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.methods {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.form-title.copy {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}

.form-title.copy i {
    font-size: 16px;
    color: var(--white);
    cursor: pointer;
}

.aspin-design .methods {
    max-height: 700px;
    min-width: min-content;
    overflow: auto;
    padding-right: 30px;
}

.aspin-design button {
    height: 100%;
}

.amounts, .address {
    display: flex;
    flex-direction: column;
    margin-left: 20px
}

.address {
    flex: 1 1 auto
}

.address input {
    flex: 1 1 auto;
}

.address .form-title {
    margin-bottom: 20px;
}

.address .form-row.nopad {
    margin-bottom: 0;
}

.address .form-row.row {
    display: flex;
    flex-direction: row !important;
}

.aspin-design .cabinet th {
    background-color: #000;
}

.light-theme .aspin-design .cabinet th {
    background-color: var(--aspin-background-active)
}

.amounts .buttons-row {
    align-items: stretch;
}

.amounts .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.amounts .item {
    color: var(--white-to-black);
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    padding: 15px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    margin: 0 5px 25px 0;
    width: 200px;
    transition: background-color ease-in-out 0.3s;
    background: var(--background-dark-mid);
}

.amounts .item:hover {
    background-color: var(--fs-border);
}

.address-generated {
    background: var(--background-dark-mid);
    font-size: 18px;
    color: var(--white);
    font-weight: 300;
    padding: 30px;
    display: grid;
    place-content: center;
    color: var(--white-to-black);
    text-align: center;
}

.amounts .currency {
    height: 50px;
    position: relative;
    margin-left: 5px;
    padding: 5px;
    color: var(--white-to-black);
    font-weight: bold;
    border-radius: 5px;
    border: none;
}

.amount {
    margin: 15px 0;
}

.cont {
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    height: 49px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    position: relative;
}

.row-fs .cont {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    place-content: center;
}

.aspin-design .amounts .item,
.aspin-design .row-fs .cont {
    border: 1px solid #34384C;
    border-radius: 50px;
    height: 45px;
}

.aspin-design .row-fs .cont {
    padding: 0 20px;
}

.cont .amount {
    margin: 3px 0 3px 5px;
}

.cont input {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    color: var(--white-to-black);
    padding-left: 10px;
    width: 100%;
    margin-top: 0;
}

.cont .sign {
    font-size: 1.5em;
    font-weight: 500;
}

.bank-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 400;
}

.bank-details div {
    margin-bottom: 8px;
}

.bank-details .bank-val {
    color: var(--white);
    margin-left: 5px;
}

.crypto-icons {
    display: grid;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.crypto-icons .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    flex: 1 1 auto;
    border: 1px solid var(--fs-border);
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
}

.crypto-icons .item.active {
    background: var(--background-dark-mid);
}
.light-theme .crypto-icons .item.active {
    background: #34384C;
}

.crypto-icons .item:last-child {
    margin-right: 0;
}

.crypto-icons .item img {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
}

.crypto-icons .item .name {
    font-size: 1.2em;
    font-weight: 400;
}

.bank-select {
    width: 100%;
    margin: 10px 0;
}

.other {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-rows: auto;
    gap: 30px;
}

.aspin-design .other {
    height: 700px;
    overflow: auto;
}

.light-theme .aspin-design .other {
    background: white;
}

.other a {
    display: inline-grid;
    place-items: center center;
    height: 70px;
}

.other img {
    object-fit: contain;
    max-width: 100%;
    filter: grayscale(100%) contrast(30%);
    max-height: 70px;
}

.other img:hover {
    filter: grayscale(0%) contrast(100%);
}

.light-theme .other img:hover {
    filter: grayscale(100%) contrast(30%);
}

.has-bank-transfer .form-title {
    text-transform: uppercase;
}

.has-bank-transfer .form-title:not(:first-child) {
    margin-top: 30px;
}

.bank-steps li {
    width: 400px;
    min-height: 150px;
    margin-top: 20px;
    border-radius: 5px;
    background: var(--background-dark-mid);
    padding: 30px;
    display: grid;
    place-items: center start;
}

.bank-steps li * {
    text-align: center;
}

.bank-steps li > *:not(:last-child) {
    margin-bottom: 20px;
}

.bank-steps li div.center {
    width: 100%;
    text-align: center;
    font-size: 1em;
}

.bank-steps h2 span {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: inline-grid;
    place-items: center;
    font-size: 22px;
    color: var(--white-to-black);
    background: var(--fs-border);
    border-radius: 50%;
}

.light-theme .bank-steps h2 span {
    background-color: #e0e3eb;
}

.bank-currency {
    margin: 0 0 0 15px;
    padding: 10px 0;
    border-radius: 5px;
    width: 60px;
    text-align: center;
    border: none;
}

#file {
    display: none;
}

label.attach {
    margin-right: 10px;
    border: 1px solid var(--fs);
    background: var(--background-dark-mid);
    border-radius: 5px;
    color: var(--white-to-black);
    cursor: pointer;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
}

.over {
    position: relative;
}

.over::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: rgba(0, 0, 0, .5);
    border: 4px dashed white;
}

.banking-details {
    margin-bottom: 30px;
}

.banking-details > div {
    margin-top: 10px;
}

.banking-details .value {
    color: var(--white);
}

.field-container input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.fullscreen-page-sub-title {
    display: flex;
    gap: 15px;
    align-items: center;
}

@media (min-width: 1921px) {
    .other img {
        max-height: 3.5vh;
    }

    .other a {
        height: 3.5vh;
    }
}

@media (max-width: 1378px) {
    .amounts .card label {
        display: inline-block;
    }
}

@media (min-width: 1000px) {
    .row-fs {
        gap: 1vw;
    }

    .banking-details > div {
        font-size: 1vw;
        margin-top: 1vw;
    }

    .bank-steps li div.center {
        font-size: 1vw;
    }

    .bank-steps h2 {
        font-size: 1vw;
    }

    .qrcode img {
        width: 10vw;
        height: 10vw;
        border-radius: .5vw;
        border: .1vw solid rgba(255, 255, 255, 0.3);
        padding: 1vw;
    }

    .deposit {
        margin: 1.5vw 0;
    }

    .aspin-design .amounts .item,
    .aspin-design .row-fs .cont {
        border: 0.1vw solid #34384C;
        border-radius: 10vw;
        height: 3.5vw;
    }

    .aspin-design .row-fs .cont {
        padding: 0 2.4vw;
    }

    .amounts, .address {
        margin-left: 1vw;
    }

    .address .form-title {
        margin-bottom: 1vw;
    }

    .amounts .item {
        border-radius: .5vw;
        border: .1vw solid var(--fs-border);
        padding: .8vw;
        font-size: 1vw;
        margin: 0 0 1vw 0;
        width: 100%;
    }

    .bank-currency.fs {
        background-position: calc(100% - 0.35vw) calc(0.9vw), calc(100% - 0vw) calc(0.9vw), calc(100% - 3vw) 0.4vw;
    }

    .bank-currency.fs.activeSelect {
        background-position: calc(100% - 0vw) 1vw, calc(100% - .35vw) 1vw, calc(100% - 1vw) 0.4vw;
    }

    .amounts .currency.fs {
        background-position: calc(100% - 0.35vw) calc(0.8vw), calc(100% - 0vw) calc(0.8vw), calc(100% - 3vw) 0.4vw;
    }

    .amounts .currency.fs.activeSelect {
        background-position: calc(100% - 0vw) .7vw, calc(100% - .35vw) .7vw, calc(100% - 1vw) 0.4vw;
    }

    .amounts .currency {
        height: 2vw;
        padding: 0.4vw 1vw;
        border-radius: 0.1vw;
        top: 50%;
        font-size: 1vw;
        position: absolute;
        right: 0.5vw;
        transform: translateY(-50%);
    }

    .cont {
        border-radius: .5vw;
        border: .1vw solid var(--fs-border);
        background: var(--background-dark-mid);
        height: 3vw;
        padding: 0 .5vw;
        position: relative;
    }

    .proceed {
        height: auto;
    }

    .cont .amount {
        margin: .15vw 0 .15vw .25vw;
    }

    .cont .sign {
        bottom: 0.55vw;
        font-size: 1.5vw;
    }

    .bank-details {
        font-size: 1.1vw;
    }

    .bank-details div {
        margin-bottom: .4vw;
    }

    .bank-details .bank-val {
        margin-left: .25vw;
    }

    .crypto-icons {
        margin-bottom: 2vw;
    }

    .crypto-icons .item {
        margin-right: 1vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .5vw;
    }

    .crypto-icons .item img {
        width: 1vw;
        height: 1vw;
        margin-bottom: .25vw;
    }

    .crypto-icons .item .name {
        font-size: 1.25vw;
        font-weight: 400;
    }

    .has-bank-transfer .form-title:not(:first-child) {
        margin-top: 1.5vw;
    }

    .bank-steps li {
        width: 20vw;
        min-height: 7.8vw;
        margin-top: 1vw;
        border-radius: .5vw;
        padding: 1.5vw;
    }

    .bank-steps li > *:not(:last-child) {
        margin-bottom: 1vw;
    }

    .bank-steps h2 span {
        margin-right: .25vw;
        width: 2vw;
        height: 2vw;
        font-size: 1.1vw;
    }

    label.attach {
        margin-right: .5vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .4vw .625vw;
        font-size: .7vw;
    }

    .light-theme label.attach {
        border-color: var(--white-to-black);
    }

    .over::after {
        top: .15vw;
        left: .15vw;
        right: .15vw;
        bottom: .15vw;
        border: .4vw dashed white;
    }

    .bank-currency {
        padding: 0.7vw 0;
        border-radius: 0.5vw;
        width: 5.125vw;
        top: 50%;
        font-size: .7vw;
        right: 0.5vw;
        transform: translateY(-50%);
    }

    .amount {
        font-size: 1vw;
        margin: .7vw 0;
    }

    .cont input {
        font-size: 1.3vw;
        height: 100%;
        padding-left: .3vw;
    }

    .other {
        gap: 2vw;
        height: 100%;
    }

    i {
        font-size: 1.25vw;
    }

    .address-generated {
        padding: 7vw 12vw;
        border-radius: 0.5vw;
        font-size: 1vw;
    }

    .form-container input {
        border: .1vw solid var(--fs-border);
    }
}

@media (max-width: 1000px) {
    .bank-steps li {
        width: 100%;
        padding: 10px;
    }

    .form-title {
        margin-top: 20px;
    }

    .copy {
        margin-left: 0;
        margin-top: 15px;
    }

    .cont .sign {
        bottom: 12px;
    }

    .amounts .item {
        margin: 0 0 25px 0;
    }

    .row-fs {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        min-width: 15vw;
    }

    .bank-currency {
        right: 10px;
        top: 5px;
        width: 80px;
    }

    .proceed {
        height: 49px;
    }

    .amounts .currency {
        height: 40px;
        width: 50px;
    }

    .amounts .currency.fs {
        margin-left: 0;
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .deposit {
        flex-wrap: wrap;
    }

    .methods {
        width: 100%;
        margin-bottom: 20px;
    }

    .amounts {
        margin-left: 0 !important;
        width: 100%;
    }

    .amounts .col {
        flex: 1 1 calc(50% - 10px)
    }

    .amounts .col:first-child {
        margin-right: 0;
    }

    .amounts .item {
        width: auto;
    }

    .proceed, .cont {
        width: 100%;
    }

    .address {
        margin-left: 0;
    }

    .currency.fs {
        background-position: calc(100% - 5px) calc(15px), calc(100% - 0px) calc(15px), calc(100% - 25px) 4px;
    }

    .currency.fs.activeSelect {
        background-position: calc(100% - 0px) 15px, calc(100% - 5px) 15px, calc(100% - 1px) 3px;
    }

    .qrcode img {
        background: var(--fs);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        padding: 20px;
    }
}
.hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
    margin: 20px auto 0;
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid #fff;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.aspin-design .bank-currency {
    padding: 0 !important;
}
</style>
